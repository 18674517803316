import React, { useState } from "react";
import Button from "./Button";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";

interface ButtonWithConfirmationProps {
  onConfirm: () => void;
  onCancel: () => void;
  children: React.ReactNode;
  confirmText: string;
  cancelText: string;
}

const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProps> = ({
  onConfirm,
  onCancel,
  children,
  confirmText,
  cancelText,
}) => {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenConfirmationModal = () => {
    setConfirmationModalIsOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
  };

  const handleConfirm = () => {
    setConfirmationModalIsOpen(false);
    onConfirm();
  };

  return (
    <>
      <Button onClick={handleOpenConfirmationModal} right isVertical>
        {children}
      </Button>
      <Modal
        open={confirmationModalIsOpen}
        onClose={handleCloseConfirmationModal}
        className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-75 z-[1000]"
      >
        <div className="bg-beige p-6 rounded shadow-lg max-w-lg w-full border-4 border-darkBlue text-darkBlue">
          <h2 className="text-xl font-chillaxBold mb-4">
            {t("actions.confirmTitle")}
          </h2>
          <p className="font-chillaxMedium">{t("actions.confirmText")}</p>
          <div className="flex justify-end space-x-4 mt-6">
            <Button onClick={handleCloseConfirmationModal}>{cancelText}</Button>
            <Button onClick={handleConfirm}>{confirmText}</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ButtonWithConfirmation;
