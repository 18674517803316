import React from "react";
import { CardType } from "../types/CardType";
import { useTranslation } from "react-i18next";

import ExportCards from "./ExportCards";
import { exportOptions, exportToolsSealedOptions } from "../data/exportData";
import SmallSeparator from "../components/SmallSeparator";
import Button from "../components/Button";

interface SealedActionsProps {
  opened: boolean;
  deckCards: CardType[];
  handleOpenPacks: () => void;
  resetPacks: () => void;
  nbBooster: number;
  setNbBooster: (number: number) => void;
}

const SealedActions: React.FC<SealedActionsProps> = ({
  opened,
  deckCards,
  handleOpenPacks,
  resetPacks,
  nbBooster,
  setNbBooster,
}) => {
  const { t } = useTranslation();

  const handleResetAndOpenPacks = () => {
    resetPacks();
    handleOpenPacks();
  };

  return (
    <div className="flex flex-col justify-center relative">
      <div className="flex justify-center">
        <div className="flex">
          <div className="relative">
            <label
              htmlFor="nbBooster"
              className="absolute -top-5 left-1 text-sm font-chillaxMedium text-darkBlue whitespace-nowrap"
            >
              {t("filterTitle.boosters")}
            </label>
            <input
              type="number"
              id="nbBooster"
              value={nbBooster}
              onChange={(e) => setNbBooster(Number(e.target.value))}
              min="1"
              max="10"
              className="block w-full max-w-36 shadow sm:text-sm font-chillaxBold rounded-l-md rounded-r-none h-9 p-5 outline-none border-4 bg-lightBlue border-darkBlue"
            />
          </div>
          <Button
            onClick={opened ? handleResetAndOpenPacks : handleOpenPacks}
            right
          >
            {opened ? t("actions.reopen") : t("actions.openPacks")}
          </Button>
        </div>
        {opened && <SmallSeparator />}
        {opened && (
          <ExportCards
            cards={deckCards}
            exportOptions={exportOptions}
            exportToolsOptions={exportToolsSealedOptions}
            isSealed
          />
        )}
      </div>
    </div>
  );
};

export default SealedActions;
