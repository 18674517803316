import {
  CardType,
  CardStatsInfo,
  Card,
  Exchange,
  SummaryProps,
} from "../types/CardType";
import {
  CARD_TYPE,
  RARITY,
  FACTIONS,
  PRIMARY_SORT_MODE,
  SECONDARY_SORT_MODE,
  SORT_DIRECTION,
} from "../constants/constants";
import { createMyCardsFromText } from "./formatCards";

// Fonction pour analyser les listes de cartes à partir des entrées
export const parseCardList = (input: string): Card[] => {
  return input.split("\n").map((line) => {
    const [quantity, reference] = line.split(" ");
    return { reference, quantity: parseInt(quantity) };
  });
};

export const getTotalNumberOfCards = (cards: CardType[]): number => {
  return cards.reduce((total, card) => total + (card.inMyCollection ?? 0), 0);
};

export const getMissingCards = (
  myCards: CardType[],
  allCards: CardType[],
  rareQuantity: number,
  commonQuantity: number,
  raritiesToInclude?: string[]
): CardType[] => {
  // Créer un dictionnaire des quantités de cartes que nous possédons
  const myCardQuantities = myCards.reduce((acc, card) => {
    acc[card.reference] = card.inMyCollection || 0;
    return acc;
  }, {} as Record<string, number>);

  // Filtrer les cartes manquantes
  const missingCards = allCards.filter((card) => {
    const currentQuantity = myCardQuantities[card.reference] || 0;
    const matchesRarity = raritiesToInclude
      ? raritiesToInclude.includes(card.rarity)
      : true;

    // Déterminer la quantité désirée basée sur la rareté de la carte
    const desiredQuantity =
      card.rarity === "RARE"
        ? rareQuantity
        : card.rarity === "COMMON"
        ? commonQuantity
        : rareQuantity; // Utilisation de rareQuantity par défaut pour les autres raretés

    return (
      currentQuantity < desiredQuantity &&
      card.rarity !== RARITY.UNIQUE &&
      card.type !== CARD_TYPE.TOKEN &&
      card.type !== CARD_TYPE.TOKEN_MANA &&
      matchesRarity
    );
  });

  // Calculer les quantités manquantes et retourner les cartes manquantes
  return missingCards.map((card) => {
    const desiredQuantity =
      card.rarity === "RARE"
        ? rareQuantity
        : card.rarity === "COMMON"
        ? commonQuantity
        : rareQuantity; // Utilisation de rareQuantity par défaut pour les autres raretés

    return {
      ...card,
      inMyCollection: desiredQuantity - (myCardQuantities[card.reference] || 0),
    };
  });
};

export const getMissingDeckCards = (
  myCards: CardType[],
  deckCards: CardType[]
): CardType[] => {
  // Créer un dictionnaire des quantités de cartes que nous possédons
  const myCardQuantities = myCards.reduce((acc, card) => {
    acc[card.reference] = card.inMyCollection || 0;
    return acc;
  }, {} as Record<string, number>);

  // Filtrer les cartes manquantes
  const missingCards = deckCards.filter((deckCard) => {
    const currentQuantity = myCardQuantities[deckCard.reference] || 0;
    return currentQuantity < (deckCard.inMyCollection || 0);
  });

  // Calculer les quantités manquantes et retourner les cartes manquantes
  return missingCards.map((deckCard) => {
    const currentQuantity = myCardQuantities[deckCard.reference] || 0;
    const missingQuantity = (deckCard.inMyCollection || 0) - currentQuantity;

    return {
      ...deckCard,
      inMyCollection: missingQuantity, // Quantité de cartes manquantes
    };
  });
};

export const getExcessCards = (
  myCards: CardType[],
  rareQuantity: number,
  commonQuantity: number,
  raritiesToInclude?: string[]
): CardType[] => {
  // Filtrer les cartes excédentaires
  const excessCards = myCards.filter((card) => {
    const currentQuantity = card.inMyCollection || 0;
    const matchesRarity = raritiesToInclude
      ? raritiesToInclude.includes(card.rarity)
      : true;

    // Déterminer la quantité désirée basée sur la rareté de la carte
    const desiredQuantity =
      card.rarity === "RARE"
        ? rareQuantity
        : card.rarity === "COMMON"
        ? commonQuantity
        : rareQuantity; // Utilisation de rareQuantity par défaut pour les autres raretés

    return (
      currentQuantity > desiredQuantity &&
      card.rarity !== RARITY.UNIQUE &&
      card.type !== CARD_TYPE.TOKEN &&
      card.type !== CARD_TYPE.TOKEN_MANA &&
      matchesRarity
    );
  });

  // Calculer les quantités excédentaires et retourner les cartes excédentaires
  return excessCards.map((card) => {
    const desiredQuantity =
      card.rarity === "RARE"
        ? rareQuantity
        : card.rarity === "COMMON"
        ? commonQuantity
        : rareQuantity; // Utilisation de rareQuantity par défaut pour les autres raretés

    return {
      ...card,
      inMyCollection: (card.inMyCollection || 0) - desiredQuantity,
    };
  });
};

// Fonction pour calculer les échanges possibles
export const calculateExchanges = (
  cards1: Card[],
  cards2: Card[],
  cardsNeeded: number
): Exchange[] => {
  const countCards = (cards: Card[]): Record<string, number> => {
    const cardCount: Record<string, number> = {};
    cards.forEach((card) => {
      cardCount[card.reference] =
        (cardCount[card.reference] || 0) + card.quantity;
    });
    return cardCount;
  };

  const cardCountA = countCards(cards1);
  const cardCountB = countCards(cards2);

  const cardExchanges: Exchange[] = [];

  for (const card in cardCountA) {
    if (
      cardCountA[card] > cardsNeeded &&
      (cardCountB[card] || 0) < cardsNeeded
    ) {
      const excessInA = cardCountA[card] - cardsNeeded;
      const neededInB = cardsNeeded - (cardCountB[card] || 0);
      const exchangeCount = Math.min(excessInA, neededInB);

      if (exchangeCount > 0) {
        cardExchanges.push({
          card,
          from: "1",
          to: "2",
          count: exchangeCount,
        });
      }
    }
  }

  for (const card in cardCountB) {
    if (
      cardCountB[card] > cardsNeeded &&
      (cardCountA[card] || 0) < cardsNeeded
    ) {
      const excessInB = cardCountB[card] - cardsNeeded;
      const neededInA = cardsNeeded - (cardCountA[card] || 0);
      const exchangeCount = Math.min(excessInB, neededInA);

      if (exchangeCount > 0) {
        cardExchanges.push({
          card,
          from: "2",
          to: "1",
          count: exchangeCount,
        });
      }
    }
  }

  return cardExchanges;
};

// Calculer un résumé des cartes
export const calculateSummary = (cards: CardType[]) => {
  const summary: SummaryProps = {
    total: cards.length,
    heroes: 0,
    characters: 0,
    spells: 0,
    permanents: 0,
    common: 0,
    rare: 0,
    unique: 0,
    yz: 0,
    ax: 0,
    br: 0,
    mu: 0,
    ly: 0,
    or: 0,
  };

  cards.forEach((card) => {
    if (card.type === CARD_TYPE.HERO) summary.heroes += 1;
    if (card.type === CARD_TYPE.CHARACTER) summary.characters += 1;
    if (card.type === CARD_TYPE.SPELL) summary.spells += 1;
    if (card.type === CARD_TYPE.PERMANENT) summary.permanents += 1;
    if (card.rarity === RARITY.COMMON) summary.common += 1;
    if (card.rarity === RARITY.RARE) summary.rare += 1;
    if (card.rarity === RARITY.UNIQUE) summary.unique += 1;

    if (card.mainFaction === FACTIONS.YZ) summary.yz += 1;
    if (card.mainFaction === FACTIONS.AX) summary.ax += 1;
    if (card.mainFaction === FACTIONS.BR) summary.br += 1;
    if (card.mainFaction === FACTIONS.MU) summary.mu += 1;
    if (card.mainFaction === FACTIONS.LY) summary.ly += 1;
    if (card.mainFaction === FACTIONS.OR) summary.or += 1;
  });

  return summary;
};

export const createMyCards = (
  allCards: CardType[],
  cardInfos: CardStatsInfo[]
): CardType[] => {
  return allCards
    .filter((card) =>
      cardInfos?.some((info) => info.reference === card.reference)
    )
    .map((card) => {
      const matchedInfo = cardInfos.find(
        (info) => info.reference === card.reference
      );
      if (matchedInfo) {
        return {
          ...card,
          inMyCollection: matchedInfo.inMyCollection,
          inMyTradelist: matchedInfo.inMyTradelist,
          inMyWantlist: matchedInfo.inMyWantlist,
        };
      }
      return card;
    });
};

const parseMainCost = (mainCost: string | undefined | null): number => {
  if (!mainCost) return 0;
  const formattedCost = mainCost.replace(/[^0-9]/g, ""); // Enlève tout sauf les chiffres
  return parseInt(formattedCost, 10) || 0; // Convertit en nombre ou renvoie 0
};

export const sortCards = (
  cards: CardType[],
  primarySortMode: string,
  secondarySortMode: string,
  secondaryDirection: string,
  lang: string
): CardType[] => {
  const factionOrder = ["YZ", "AX", "BR", "MU", "LY", "OR", "NE"];
  const rarityOrder = ["COMMON", "RARE", "UNIQUE"];
  const typeOrder = [
    "HERO",
    "CHARACTER",
    "SPELL",
    "PERMANENT",
    "TOKEN",
    "FOILER",
    "TOKEN_MANA",
  ];

  const compare = (
    a: CardType,
    b: CardType,
    mode: string,
    secondarySortMode: string
  ): number => {
    if (mode === PRIMARY_SORT_MODE.BY_TYPE) {
      if (secondarySortMode === SECONDARY_SORT_MODE.BY_NOTHING) {
        if (a.type === "HERO" && b.type !== "HERO") return -1;
        if (b.type === "HERO" && a.type !== "HERO") return 1;
        if (a.rarity !== b.rarity) {
          return rarityOrder.indexOf(a.rarity) - rarityOrder.indexOf(b.rarity);
        }
        if (a.type !== b.type) {
          return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
        }
        if (a.mainFaction !== b.mainFaction) {
          return (
            factionOrder.indexOf(a.mainFaction) -
            factionOrder.indexOf(b.mainFaction)
          );
        }
      } else {
        if (a.type !== b.type) {
          return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
        }
      }
    } else if (mode === PRIMARY_SORT_MODE.BY_FACTION) {
      if (secondarySortMode === SECONDARY_SORT_MODE.BY_NOTHING) {
        if (a.mainFaction !== b.mainFaction) {
          return (
            factionOrder.indexOf(a.mainFaction) -
            factionOrder.indexOf(b.mainFaction)
          );
        }
        if (a.rarity !== b.rarity) {
          return rarityOrder.indexOf(a.rarity) - rarityOrder.indexOf(b.rarity);
        }
        if (a.type !== b.type) {
          return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
        }
      } else {
        if (a.mainFaction !== b.mainFaction) {
          return (
            factionOrder.indexOf(a.mainFaction) -
            factionOrder.indexOf(b.mainFaction)
          );
        }
      }
    }
    return 0;
  };

  const compareWithDirection = (
    a: CardType,
    b: CardType,
    mode: string,
    direction: string,
    lang: string
  ): number => {
    if (mode === SECONDARY_SORT_MODE.BY_LATEST_ADDITION) {
      const dateA = a.latestAddition ? new Date(a.latestAddition).getTime() : 0;
      const dateB = b.latestAddition ? new Date(b.latestAddition).getTime() : 0;
      return direction === SORT_DIRECTION.ASCENDING
        ? dateA - dateB
        : dateB - dateA;
    } else if (mode === SECONDARY_SORT_MODE.BY_MAIN_COST) {
      const costA = parseMainCost(a.elements?.MAIN_COST);
      const costB = parseMainCost(b.elements?.MAIN_COST);
      return direction === SORT_DIRECTION.ASCENDING
        ? costA - costB
        : costB - costA;
    } else if (mode === SECONDARY_SORT_MODE.BY_NAME) {
      const nameA = a.name[lang] || "";
      const nameB = b.name[lang] || "";
      return direction === SORT_DIRECTION.ASCENDING
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    } else if (mode === SECONDARY_SORT_MODE.BY_NUMBER) {
      const numberA = a.collectorNumberPrinted || "";
      const numberB = b.collectorNumberPrinted || "";

      const extractNumber = (number: string) => {
        const match = number.match(/\d+/);
        return match ? parseInt(match[0], 10) : 0;
      };

      const numA = extractNumber(numberA);
      const numB = extractNumber(numberB);

      return direction === SORT_DIRECTION.ASCENDING ? numA - numB : numB - numA;
    }
    return 0;
  };

  return cards.sort((a, b) => {
    const primaryComparison = compare(a, b, primarySortMode, secondarySortMode);
    if (primaryComparison !== 0) return primaryComparison;
    return compareWithDirection(
      a,
      b,
      secondarySortMode,
      secondaryDirection,
      lang
    );
  });
};

export const getRandomCards = (
  cards: CardType[],
  count: number,
  rarities?: string[],
  types?: string[],
  factions?: string[]
): CardType[] => {
  // Types à exclure par défaut
  const excludedTypes = ["TOKEN", "FOILER", "TOKEN_MANA"];

  let filteredCards = cards;

  if (rarities && rarities.length > 0) {
    filteredCards = filteredCards.filter((card) =>
      rarities.includes(card.rarity)
    );
  }

  if (types && types.length > 0) {
    filteredCards = filteredCards.filter((card) => types.includes(card.type));
  } else {
    filteredCards = filteredCards.filter(
      (card) => !excludedTypes.includes(card.type)
    );
  }

  if (factions && factions.length > 0) {
    filteredCards = filteredCards.filter((card) =>
      factions.includes(card.mainFaction)
    );
  }

  const shuffledCards = filteredCards.sort(() => 0.5 - Math.random());

  return shuffledCards.slice(0, count);
};

export const handleTextToCard = (
  text: string,
  allCards: CardType[]
): CardType[] | null => {
  const cardEntries = text.split("\n");

  const cardData: CardStatsInfo[] = cardEntries.map((entry) => {
    const [count, reference] = entry.split(" ");
    return {
      reference,
      inMyCollection: parseInt(count, 10),
    } as CardStatsInfo;
  });

  if (allCards) {
    const { includedCards } = createMyCardsFromText(allCards, cardData);
    if (includedCards.length > 0) {
      return includedCards;
    } else {
      console.error("error");
      return null;
    }
  }
  return null;
};

export const removeAccents = (str: string): string => {
  return str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const filterCardsByQuery = (
  cards: CardType[],
  query: string,
  currentLanguage: string
): CardType[] => {
  const nameTerms: string[] = [];
  const effectTerms: string[] = [];

  const regex = /"([^"]+)"|(\S+)/g;
  let match;

  while ((match = regex.exec(query)) !== null) {
    if (match[1]) {
      effectTerms.push(match[1].toLowerCase());
    } else if (match[2]) {
      nameTerms.push(match[2].toLowerCase());
    }
  }

  return cards.filter((card) => {
    const cardName = removeAccents(card.name[currentLanguage]?.toLowerCase());
    const mainEffect = card.elements?.MAIN_EFFECT
      ? removeAccents(card.elements.MAIN_EFFECT[currentLanguage]?.toLowerCase())
      : "";

    const matchesName = nameTerms.every((term) =>
      cardName?.includes(removeAccents(term))
    );

    const matchesEffect = effectTerms.every((term) =>
      mainEffect?.includes(removeAccents(term))
    );

    return matchesName && matchesEffect;
  });
};
