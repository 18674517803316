import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { parseCardList } from "../utils/cardUtilities";
import CardList from "../parts/CardList";
import { CardType } from "../types/CardType";
import Button from "../components/Button";
import { useTranslation, Trans } from "react-i18next";
import useFetchCards from "../hooks/useFetchCards";
import Separator from "../components/Separator";
import CardSelectionModal from "../parts/CardSelectionModal";
import { linkTo } from "../utils/linkTo";
import Layout from "../parts/Layout";
import cardsData from "../data/cards.json";

const DonationHelper = () => {
  const { t } = useTranslation();
  const { allCards } = useFetchCards(cardsData);
  const [list, setList] = useState("");
  const [selectedCards, setSelectedCards] = useState<CardType[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setList(e.target.value);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleValidate = () => {
    const parsedCards = parseCardList(list);
    const selected = allCards?.filter((card) =>
      parsedCards?.some((parsedCard) => parsedCard.reference === card.reference)
    );
    if (selected) {
      setSelectedCards(selected as CardType[]);
    }
  };

  const addCardBack = (card: CardType) => {
    const isSelected = selectedCards?.some(
      (c) => c.reference === card.reference
    );

    if (isSelected) {
      setSelectedCards(
        selectedCards.filter((c) => c.reference !== card.reference)
      );
    }
  };

  document.title = t("navigation.donation") + " | Altered Card";

  return (
    <Layout>
      <div className="space-y-2">
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 justify-center items-center">
          <Button onClick={handleOpenModal}>{t("actions.selectCards")}</Button>
          <span className="font-chillaxBold text-darkBlue">
            {t("connecter.or")}
          </span>
          <div className="flex justify-center w-full md:w-1/3">
            <textarea
              rows={4}
              placeholder={t("actions.enterCards")}
              value={list}
              onChange={handleInputChange}
              className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
            />
            <Button onClick={handleValidate} isVertical right>
              {t("actions.validate")}
            </Button>
          </div>
        </div>
        <div className="flex justify-center items-center font-chillaxMedium bottom-0 px-2 md:px-8 w-full text-center">
          <span>
            <Trans
              i18nKey="navigation.tutoDiscord"
              components={{
                LinkTo: linkTo({
                  dest: "https://discord.gg/8yvbc3bk8h",
                }),
                Bold: <strong />,
              }}
            />
          </span>
        </div>
      </div>

      {selectedCards.length > 0 && (
        <>
          <Separator />
          <CardList
            cards={selectedCards}
            addCardBack={addCardBack}
            isDonation
          />
        </>
      )}

      <CardSelectionModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        cards={myCards?.length > 0 ? myCards : allCards || []}
        selectedCards={selectedCards}
        setSelectedCards={setSelectedCards}
        myCollection={myCards}
      />
    </Layout>
  );
};

export default DonationHelper;
