import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardType } from "../types/CardType";
import CardModal from "./CardModal";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../constants/constants";
import ShinyCard from "@mountainpass/react-pokemon-cards";
import { isMobile } from "react-device-detect";
import { FaStar } from "react-icons/fa";
import { RootState } from "../store/store";
import {
  addFavoriteCard,
  removeFavoriteCard,
} from "../store/slices/collectionCardsSlice";

interface CardProps {
  card: CardType;
  foil?: boolean;
  freeze?: boolean;
  noClick?: boolean;
  asFavorite?: boolean;
  addDeckCard?: () => void | undefined;
  addCardBack?: () => void | undefined;
  selected?: boolean;
}

const Card: React.FC<CardProps> = ({
  card,
  foil,
  freeze,
  noClick,
  asFavorite,
  addDeckCard,
  addCardBack,
  selected = false,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const myFavoriteCard = useSelector(
    (state: RootState) => state.collectionCards.favoriteCards
  );

  const isFavorite = myFavoriteCard?.some(
    (favoriteCard) => favoriteCard.reference === card.reference
  );

  const openModal = () => {
    !noClick && setModalIsOpen(true);
  };
  const closeModal = () => setModalIsOpen(false);

  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  const handleClickFavorite = () => {
    if (isFavorite) {
      dispatch(removeFavoriteCard(card));
    } else {
      dispatch(addFavoriteCard(card));
    }
  };

  return (
    <div className={`relative rounded-lg ${selected ? "opacity-50" : ""}`}>
      {asFavorite && (
        <FaStar
          onClick={handleClickFavorite}
          className={`absolute z-10 w-10 h-10 right-0 ${
            isFavorite ? "text-darkBlue" : "text-white"
          }`}
        />
      )}
      <div onClick={openModal} className={`${!noClick && "cursor-pointer"}`}>
        {freeze || isMobile ? (
          <>
            <img
              src={card.imagePath[currentLanguage]}
              alt={card.name[currentLanguage]}
              className="w-full h-auto rounded-lg custom-shadow"
              onError={(e) => {
                e.currentTarget.src = card.imagePath["en"];
              }}
            />
            {!card.inMyCollection ||
              (!card.needToExchange && (
                <div className="absolute !w-8 !h-8 md:!w-12 md:!h-12 px-2 md:py-1 rounded flex items-center justify-center bottom-3 md:bottom-5 lg:bottom-4 right-2 md:right-2.5 md:text-2xl font-chillaxMedium border-2 bg-gray-800 text-white border-white">
                  {card.inMyCollection}
                </div>
              ))}
          </>
        ) : (
          <ShinyCard
            rarity={`${foil ? "rare holo v" : "custom"}`}
            supertype="pokémon"
            subtype="basic"
            dataGallery="true"
          >
            <img
              src={card.imagePath[currentLanguage]}
              alt={card.name[currentLanguage]}
              className="w-full h-auto rounded"
            />
            {!card.inMyCollection ||
              (!card.needToExchange && (
                <div className="absolute !w-8 !h-8 md:!w-12 md:!h-12 px-2 md:py-1 rounded flex items-center justify-center bottom-3 md:bottom-5 lg:bottom-4 right-2 md:right-2.5 md:text-2xl font-chillaxMedium border-2 bg-gray-800 text-white border-white">
                  {card.inMyCollection}
                </div>
              ))}
          </ShinyCard>
        )}
      </div>
      <div className="absolute z-50">
        <CardModal
          card={card}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
      </div>
      {addDeckCard && (
        <button
          onClick={addDeckCard}
          className="absolute -bottom-3 px-4 py-0.5 rounded w-full transition font-chillaxMedium border-4 border-darkBlue bg-darkBlue text-beige hover:bg-beige hover:text-darkBlue"
        >
          {t("actions.add")}
        </button>
      )}
      {addCardBack && (
        <button
          onClick={addCardBack}
          className="absolute -bottom-3 px-4 py-0.5 rounded w-full transition font-chillaxMedium border-4 border-darkBlue bg-darkBlue text-beige hover:bg-beige hover:text-darkBlue"
        >
          {t("actions.delete")}
        </button>
      )}
    </div>
  );
};

export default Card;
