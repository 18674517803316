import React from "react";
import { useTranslation } from "react-i18next";
import { CgClose } from "react-icons/cg";
import {
  collectionFilterModes,
  cardsTypeData,
  cardsFactionData,
  cardsRarityData,
} from "../data/filtersData";

interface ActiveFiltersProps {
  filters: { type: string; values: string[] };
  onRemoveFilter: (type: string, value: string) => void;
}

const getLabel = (type: string, value: string): string => {
  switch (type) {
    case "faction":
      return (
        cardsFactionData.find((item) => item.value === value)?.label || value
      );
    case "type":
      return cardsTypeData.find((item) => item.value === value)?.label || value;
    case "rarity":
      return (
        cardsRarityData.find((item) => item.value === value)?.label || value
      );
    case "numberFilter":
      return (
        collectionFilterModes.find((item) => item.value === value)?.label ||
        value
      );
    default:
      return value;
  }
};

const ActiveFilters: React.FC<ActiveFiltersProps> = ({
  filters,
  onRemoveFilter,
}) => {
  const { t } = useTranslation();

  return (
    <div className="inline-flex mt-4 space-x-0.5">
      {filters.values.map((value) => (
        <div
          key={value}
          className="flex items-center px-3 py-1 rounded-full text-sm space-x-2 text-lightBlue bg-darkBlue"
        >
          <p className="font-chillaxMedium">
            {t(getLabel(filters.type, value))}
          </p>
          <button
            className="text-red-500"
            onClick={() => onRemoveFilter(filters.type, value)}
          >
            <CgClose />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ActiveFilters;
