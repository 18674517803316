import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import Card from "../components/Card";
import { CardType } from "../types/CardType";
import CardFilters from "./CardFilters";
import SealedSummary from "./SealedSummary";
import Pagination from "../components/Pagination";
import Separator from "../components/Separator";

interface CardListProps {
  cards: CardType[];
  setSelectedCards?: (cards: CardType[]) => void;
  selectedCards?: CardType[];
  isSealed?: boolean;
  isExchange?: boolean;
  isDonation?: boolean;
  isExport?: boolean;
  isDeck?: boolean;
  isClose?: boolean;
  asFavorite?: boolean;
  smallFilter?: boolean;
  goTo?: string;
  showCollectionFilter?: boolean;
  addDeckCard?: (card: CardType) => void;
  addCardBack?: (card: CardType) => void;
  handleClose?: () => void;
  quantity?: boolean;
}

const CardList: React.FC<CardListProps> = ({
  cards,
  setSelectedCards,
  selectedCards,
  isSealed,
  isExchange,
  isDonation,
  isDeck,
  isExport,
  isClose,
  asFavorite,
  smallFilter,
  goTo,
  showCollectionFilter,
  addDeckCard,
  addCardBack,
  handleClose,
  quantity = false,
}) => {
  const [filteredCards, setFilteredCards] = useState<CardType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = useSelector((state: RootState) => state.sidePanel.columns);

  const isFoilEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFoilEnabled
  );

  const cardsPerPage = useSelector(
    (state: RootState) => state.sidePanel.cardsPerPage
  );

  const isVisualPage = !isExchange && !isDonation && !isSealed && isFoilEnabled;

  const handleCardSelect = (card: CardType) => {
    const isSelected = selectedCards?.some(
      (c) => c.reference === card.reference
    );
    let newSelectedCards;

    if (selectedCards) {
      if (isSelected) {
        newSelectedCards = selectedCards?.filter(
          (c) => c.reference !== card.reference
        );
      } else {
        const cardWithQuantity = { ...card, nbSelected: 1 };
        newSelectedCards = [...selectedCards, cardWithQuantity];
      }
    }

    setSelectedCards && newSelectedCards && setSelectedCards(newSelectedCards);
  };

  const handleQuantityChange = (cardId: string, quantity: number) => {
    const updatedSelectedCards = selectedCards?.map((card) =>
      card.reference === cardId ? { ...card, nbSelected: quantity } : card
    );

    setSelectedCards &&
      updatedSelectedCards &&
      setSelectedCards(updatedSelectedCards);
  };

  const getGridStyle = (numCols?: number) => {
    if (numCols) {
      return {
        display: "grid",
        gridTemplateColumns: `repeat(${numCols}, minmax(0, 1fr))`,
        gap: "2.5rem",
      };
    }
  };

  const indexOfLastCard = currentPage * (cardsPerPage || 50);
  const indexOfFirstCard = indexOfLastCard - (cardsPerPage || 50);
  const currentCards = filteredCards.slice(indexOfFirstCard, indexOfLastCard);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (cardsPerPage) {
      setCurrentPage(1);
    }
  }, [cardsPerPage]);

  const totalPages = Math.ceil(filteredCards.length / (cardsPerPage || 50));

  return (
    <div
      className={`container mx-auto p-4 ${
        isSealed ? "space-y-8 md:space-y-16" : "space-y-4 md:space-y-24"
      }`}
    >
      <div className="flex flex-col items-start space-y-1">
        <CardFilters
          cards={cards}
          filteredCards={filteredCards}
          showCollectionFilter={showCollectionFilter}
          setFilteredCards={setFilteredCards}
          isDonation={isDonation}
          isExport={isExport}
          isClose={isClose}
          smallFilter={smallFilter}
          goTo={goTo}
          handleClose={handleClose}
        />
      </div>

      {isSealed && <SealedSummary cards={filteredCards} isDeck={isDeck} />}
      <div>
        {!isSealed && (
          <div className="relative mt-6 md:mt-0">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            <Separator desktop />
          </div>
        )}
        <div
          style={getGridStyle(columns)}
          className={`${!isSealed && "pt-10"} ${
            !columns &&
            `grid ${
              isSealed
                ? "grid-cols-2 md:grid-cols-3"
                : "grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
            } gap-10 md:grid-cols-3`
          }`}
        >
          {(isSealed ? filteredCards : currentCards).map((card, index) => (
            <div className="relative" key={`${card.reference}-${index}`}>
              <div
                onClick={
                  selectedCards ? () => handleCardSelect(card) : () => {}
                }
                className={`${selectedCards ? "cursor-pointer" : ""}`}
              >
                <Card
                  card={card}
                  freeze={!isVisualPage}
                  asFavorite={asFavorite}
                  selected={selectedCards?.some(
                    (c) => c.reference === card.reference
                  )}
                  addDeckCard={
                    addDeckCard ? () => addDeckCard(card) : undefined
                  }
                  addCardBack={
                    addCardBack ? () => addCardBack(card) : undefined
                  }
                  noClick={selectedCards ? true : false}
                />
              </div>
              {quantity &&
                selectedCards?.some((c) => c.reference === card.reference) && (
                  <div className="absolute bottom-0 right-0 flex justify-center items-center w-fit">
                    <input
                      id={`quantity-${card.reference}`}
                      type="number"
                      min="1"
                      value={
                        selectedCards.find(
                          (c) => c.reference === card.reference
                        )?.nbSelected
                      }
                      onChange={(e) =>
                        handleQuantityChange(
                          card.reference,
                          parseInt(e.target.value, 10)
                        )
                      }
                      className="block max-w-14 shadow sm:text-sm rounded-md p-1 border-2 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                    />
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardList;
