import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { useRegister } from "../hooks/useRegister";
import Button from "../components/Button";

interface RegisterModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const RegisterModal: React.FC<RegisterModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [password, setPassword] = useState("");
  const { register, error } = useRegister();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await register(email, pseudo, password, closeModal);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 z-[1000] bg-black"
    >
      <form
        onSubmit={handleSubmit}
        className="bg-beige p-8 rounded-lg rounded-t-none space-y-8 shadow-lg w-full max-w-md mx-auto border-4 border-t-0 border-darkBlue"
      >
        <div className="space-y-4">
          <div>
            <label className="block mb-2 text-sm font-chillaxMedium text-darkBlue">
              {`${t("user.pseudo")}*`}
            </label>
            <input
              type="text"
              onChange={(e) => setPseudo(e.target.value)}
              value={pseudo}
              className="w-full px-4 py-2 rounded-md border-4 border-darkBlue focus:outline-none"
            />
          </div>

          <div>
            <label className="block mb-2 text-sm font-chillaxMedium text-darkBlue">
              {`${t("user.email")}*`}
            </label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="w-full px-4 py-2 rounded-md border-4 border-darkBlue focus:outline-none"
            />
          </div>

          <div>
            <label className="block mb-2 text-sm font-chillaxMedium text-darkBlue">
              {`${t("user.password")}*`}
            </label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="w-full px-4 py-2 rounded-md border-4 border-darkBlue focus:outline-none"
            />
            {error && (
              <label className="text-red-500 text-sm mt-4">{error}</label>
            )}
          </div>
        </div>

        <Button isSubmit full>
          {t("actions.register")}
        </Button>
      </form>
    </Modal>
  );
};

export default RegisterModal;
