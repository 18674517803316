import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { CardType } from "../types/CardType";
import useDeckValidation from "../hooks/useDeckValidation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  MAX_FACTIONS,
  MAX_HEROES,
  MIN_CARDS,
  EXPORT_FORMAT,
  EXPORT_CARD_FORMAT,
} from "../constants/constants";
import { ExportOption } from "../types/CardType";
import { ExportHandler } from "../utils/exportUtilities";
import Separator from "../components/Separator";
import Button from "../components/Button";
import SmallSeparator from "../components/SmallSeparator";

interface ExportModalProps {
  isOpen: boolean;
  closeModal: () => void;
  cards: CardType[];
  exportOptions: ExportOption[];
  exportToolsOptions: ExportOption[];
  isSealed?: boolean;
}

const ExportModal: React.FC<ExportModalProps> = ({
  isOpen,
  closeModal,
  cards,
  exportOptions,
  exportToolsOptions,
  isSealed,
}) => {
  const { t } = useTranslation();
  const [exportCardFormat, setExportCardFormat] = useState<string>(
    EXPORT_CARD_FORMAT.ID
  );
  const [exportFormat, setExportFormat] = useState<string>(
    EXPORT_FORMAT.NORMAL
  );

  const validation = useDeckValidation(
    cards,
    MIN_CARDS,
    MAX_FACTIONS,
    MAX_HEROES
  );

  // Fonction pour appeler le handler avec des arguments dans n'importe quel ordre
  const handleExportDeck = (specificExport: ExportHandler, label?: string) => {
    if (isSealed && validation && !validation.isValid) {
      validation.errors.forEach((error: string) => toast.warning(error));
    }

    specificExport({
      cards: cards,
      needFilling: isSealed,
      label: label,
      cardFormat: exportCardFormat,
      exportFormat: exportFormat,
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 z-[1000] bg-black"
    >
      <div className="p-4 rounded shadow-lg max-w-lg w-full relative font-chillaxMedium space-y-6 border-4 border-darkBlue bg-beige">
        <button
          onClick={closeModal}
          className="absolute text-2xl font-bold top-3 right-3 text-darkBlue hover:text-gray-800 cursor-pointer"
        >
          ✕
        </button>
        <div className="space-y-4">
          <div className="flex items-center space-x-2 text-sm text-darkBlue">
            <span className="font-chillaxBold">
              {t("actions.exportCardFormat")}
            </span>
            <select
              value={exportCardFormat}
              onChange={(e) => setExportCardFormat(e.target.value)}
              className="block shadow sm:text-sm rounded p-0.5 border-2 font-chillaxMedium outline-none bg-lightBlue border-darkBlue"
            >
              {Object.entries(EXPORT_CARD_FORMAT).map(([key, value]) => (
                <option key={key} value={value}>
                  {t(`export.${key.toLowerCase()}Format`)}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center space-x-2 text-sm text-darkBlue">
            <span className="font-chillaxBold">
              {t("actions.exportFormat")}
            </span>
            <select
              value={exportFormat}
              onChange={(e) => setExportFormat(e.target.value)}
              className="block shadow sm:text-sm rounded p-0.5 border-2 font-chillaxMedium outline-none bg-lightBlue border-darkBlue"
            >
              {Object.entries(EXPORT_FORMAT).map(([key, value]) => (
                <option key={key} value={value}>
                  {t(`export.${key.toLowerCase()}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Separator />
        <div className="py-1 space-y-2">
          <label className="block text-sm font-chillaxBold text-darkBlue">
            {t("actions.export")}
          </label>
          <div className="flex">
            {exportOptions.map(({ handler, translationKey }, index) => (
              <div className="flex text-xs" key={translationKey}>
                <Button
                  onClick={() => handleExportDeck(handler, t(translationKey))}
                >
                  {t(translationKey)}
                </Button>
                {index < exportOptions.length - 1 && <SmallSeparator small />}
              </div>
            ))}
          </div>
        </div>
        <Separator />
        <div className="py-1 space-y-2">
          <label className="block text-sm font-chillaxBold text-darkBlue">
            {t("actions.exportExternalTools")}
          </label>
          <div className="grid grid-cols-2 gap-1">
            {exportToolsOptions.map(({ handler, translationKey }, index) => (
              <div className="flex flex-col text-xs" key={translationKey}>
                <Button
                  onClick={() => handleExportDeck(handler, t(translationKey))}
                >
                  {t(translationKey)}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExportModal;
