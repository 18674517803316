import { useState } from "react";

export const useCollection = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const updateCollection = async (collection, user) => {
    setIsLoading(true)
    setError(null)

    const response = await fetch('https://www.api.altered-card.com/api/user/cardsCollection', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${user?.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ collection })
    })
    const json = await response.json()

    if (!response.ok) {
      setIsLoading(false)
      setError(json.error)
    }
    if (response.ok) {
      setIsLoading(false)
    }
  }

  const getCollection = async (user) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://www.api.altered-card.com/api/user/cardsCollection', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${user?.token}`,
          'Content-Type': 'application/json'
        }
      });

      const json = await response.json();

      if (!response.ok) {
        setIsLoading(false);
        setError(json.error);
        return null;
      }

      setIsLoading(false);
      return json.cards;

    } catch (error) {
      setIsLoading(false);
      setError('Une erreur s\'est produite lors de la récupération de la collection');
      return null;
    }
  };


  return { updateCollection, getCollection, isLoading, error }
}