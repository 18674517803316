import { useState } from "react";
import { CardType } from "../types/CardType";
import { drawBoosterPack } from "../utils/boosterUtilities";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../constants/constants";

const useSealedSimulator = (sortedCards: any, numBoosters: number = 6) => {
  const [openedCards, setOpenedCards] = useState<CardType[]>([]);
  const [deckCards, setDeckCards] = useState<CardType[]>([]);
  const [opened, setOpened] = useState<boolean>(false);

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  const handleOpenPacks = () => {
    let newOpenedCards: CardType[] = [];

    for (let i = 0; i < numBoosters; i++) {
      newOpenedCards = [
        ...newOpenedCards,
        ...drawBoosterPack(sortedCards, currentLanguage),
      ];
    }

    setOpenedCards(newOpenedCards);
    setOpened(true);
  };

  const resetPacks = () => {
    setOpenedCards([]);
    setDeckCards([]);
    setOpened(false);
  };

  const addDeckCard = (card: CardType) => {
    setOpenedCards((prev) => {
      const index = prev.findIndex((c) => c.reference === card.reference);
      if (index !== -1) {
        const updatedCards = [...prev];
        updatedCards.splice(index, 1);
        return updatedCards;
      }
      return prev;
    });
    setDeckCards((prev) => [...prev, card]);
  };

  const addCardBack = (card: CardType) => {
    setDeckCards((prev) => {
      const index = prev.findIndex((c) => c.reference === card.reference);
      if (index !== -1) {
        const updatedCards = [...prev];
        updatedCards.splice(index, 1);
        return updatedCards;
      }
      return prev;
    });
    setOpenedCards((prev) => [...prev, card]);
  };

  return {
    openedCards,
    sortedCards,
    deckCards,
    opened,
    handleOpenPacks,
    resetPacks,
    addDeckCard,
    addCardBack,
  };
};

export default useSealedSimulator;
