import { LanguageDetectorModule } from "i18next";

const CustomLanguageDetector: LanguageDetectorModule = {
  type: "languageDetector",
  detect: () => {
    const detectedLang = navigator.language || (navigator as any).userLanguage;
    return detectedLang ? detectedLang.split("-")[0] : "en";
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

export default CustomLanguageDetector;
