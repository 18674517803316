import React from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { getNavigateUrl } from "../utils/getNavigateUrl";
import { LANGUAGES } from "../constants/constants";
import { useDispatch } from "react-redux";
import { toggleSidePanel } from "../store/slices/sidePanelProfilSlice";
import { useAuthContext } from "../hooks/useAuthContext";

const NavBar: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;
  const { user } = useAuthContext();

  return (
    <nav className="w-full z-50 fixed hidden md:block">
      <div className="relative shadow-xl px-4 py-4 flex justify-between items-center bg-darkBlue text-white z-50">
        <div className="flex w-full justify-center items-center font-chillaxBold lg:text-xl">
          <div className="flex justify-center items-center w-1/2 space-x-16">
            <Link
              to={getNavigateUrl(i18n.language, "exploration")}
              className="hover:text-beige whitespace-nowrap hover:underline"
            >
              {t("navigation.exploration")}
            </Link>
            <Link
              to={getNavigateUrl(i18n.language, "collection")}
              className="hover:text-beige whitespace-nowrap hover:underline"
            >
              {t("navigation.collection")}
            </Link>
            <Link
              to={getNavigateUrl(i18n.language, "decks")}
              className="hover:text-beige whitespace-nowrap hover:underline"
            >
              {t("navigation.decks")}
            </Link>
            {user?.betaTester && (
              <Link
                to={getNavigateUrl(i18n.language, "friends")}
                className="hover:text-beige whitespace-nowrap hover:underline"
              >
                {t("navigation.friendship")}
              </Link>
            )}
          </div>
          <div className="flex justify-center items-center w-1/2 space-x-8">
            <Link
              to={getNavigateUrl(i18n.language, "donation")}
              className="hover:text-beige whitespace-nowrap hover:underline"
            >
              {t("navigation.donation")}
            </Link>
            <Link
              to={getNavigateUrl(i18n.language, "exchange")}
              className="hover:text-beige whitespace-nowrap hover:underline"
            >
              {t("navigation.simExchange")}
            </Link>

            <Link
              to={getNavigateUrl(i18n.language, "sealed")}
              className="hover:text-beige whitespace-nowrap hover:underline"
            >
              {t("navigation.sealedGenerator")}
            </Link>
            {user?.betaTester && (
              <Link
                to={getNavigateUrl(i18n.language, "uniques")}
                className="hover:text-beige whitespace-nowrap hover:underline"
              >
                {t("navigation.market")}
              </Link>
            )}
          </div>
        </div>
        <Link
          to={`${currentLanguage}/`}
          className="flex flex-col items-center absolute -bottom-8 right-1/2 w-fit"
          style={{
            transform: `translate(50%)`,
          }}
        >
          <span className="flex w-fit justify-center pb-4 px-10 text-xl font-bold font-chillaxBold uppercase whitespace-nowrap rounded-b-3xl shadow-2xl bg-darkBlue">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="Altered Sealed Logo"
              className="h-10 w-auto object-contain transform transition-transform duration-200 hover:scale-105"
              height={40}
              width={40}
            />
          </span>
        </Link>
        <div className="absolute right-3 flex space-x-2 justify-center items-center">
          <FaUser
            className="cursor-pointer"
            onClick={() => dispatch(toggleSidePanel())}
          />
          <span>/</span>
          <LanguageSelector />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
