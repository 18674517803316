import { useState } from "react";
import { useAuthContext } from './useAuthContext';

export const useRegister = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch } = useAuthContext()

  const register = async (email, pseudo, password, closeModal) => {
    setIsLoading(true)
    setError(null)

    const response = await fetch('https://www.api.altered-card.com/api/user/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, pseudo, password })
    })

    const json = await response.json()

    if (!response.ok) {
      setIsLoading(false)
      setError(json.error)
    }
    if (response.ok) {
      localStorage.setItem('user', JSON.stringify(json))

      dispatch({ type: 'LOGIN', payload: json })

      setIsLoading(false)
      closeModal()
    }
  }

  return { register, isLoading, error }
}