import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardType } from "../types/CardType";
import { LANGUAGES, NUMBER_FILTERS, VERSION } from "../constants/constants";
import FilterModal from "../components/FilterModal";
import Separator from "../components/Separator";
import {
  getTotalNumberOfCards,
  sortCards,
  filterCardsByQuery,
} from "../utils/cardUtilities";
import {
  PRIMARY_SORT_MODE,
  SECONDARY_SORT_MODE,
  SORT_DIRECTION,
} from "../constants/constants";
import { IoFilterSharp } from "react-icons/io5";
import NameFilterInput from "../components/NameFilterInput";
import ButtonGroup from "./ButtonGroup";
import Button from "../components/Button";
import ActiveFilters from "../components/ActiveFilters";
import { filterByVersion } from "../hooks/useFetchCards";

interface CardFiltersProps {
  cards: CardType[];
  isDonation?: boolean;
  isExport?: boolean;
  isClose?: boolean;
  smallFilter?: boolean;
  goTo?: string;
  filteredCards: CardType[];
  showCollectionFilter?: boolean;
  setFilteredCards: (filtered: CardType[]) => void;
  handleClose?: () => void;
}

const CardFilters: React.FC<CardFiltersProps> = ({
  cards,
  isDonation,
  isExport,
  isClose,
  smallFilter,
  goTo,
  filteredCards,
  showCollectionFilter = false,
  setFilteredCards,
  handleClose,
}) => {
  const { t, i18n } = useTranslation();
  const [cardTypes, setCardTypes] = useState<string[]>([]);
  const [primarySortMode, setPrimarySortMode] = useState<string>(
    PRIMARY_SORT_MODE.BY_TYPE
  );
  const [secondarySortMode, setSecondarySortMode] = useState<string>(
    SECONDARY_SORT_MODE.BY_NOTHING
  );
  const [secondarySortDirection, setSecondarySortDirection] = useState<string>(
    SORT_DIRECTION.ASCENDING
  );
  const [cardRarities, setCardRarities] = useState<string[]>([]);
  const [factionFilters, setFactionFilters] = useState<string[]>([]);
  const [nameFilter, setNameFilter] = useState<string>("");
  const [collectionFilterValue, setCollectionFilterValue] = useState<number>(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [collectionFilterMode, setCollectionFilterMode] = useState<string>(
    NUMBER_FILTERS.GREATER
  );
  const [version, setVersion] = useState<string>(VERSION.ALL);

  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    let filtered = [...cards];

    filtered = filterByVersion(filtered, version);

    if (cardTypes.length > 0) {
      filtered = filtered.filter((card) => cardTypes.includes(card.type));
    }
    if (cardRarities.length > 0) {
      filtered = filtered.filter((card) => cardRarities.includes(card.rarity));
    }
    if (factionFilters.length > 0) {
      filtered = filtered.filter((card) =>
        factionFilters.includes(card.mainFaction)
      );
    }
    if (
      showCollectionFilter &&
      collectionFilterMode &&
      collectionFilterValue !== undefined
    ) {
      filtered = filtered.filter((card) => {
        const count = card.inMyCollection || 0;
        switch (collectionFilterMode) {
          case NUMBER_FILTERS.EQUALS:
            return count === collectionFilterValue;
          case NUMBER_FILTERS.GREATER:
            return count > collectionFilterValue;
          case NUMBER_FILTERS.LESS:
            return count < collectionFilterValue;
          default:
            return true;
        }
      });
    }

    // Modifier la logique de filtrage pour le champ nameFilter
    if (nameFilter.trim() !== "") {
      filtered = filterCardsByQuery(filtered, nameFilter, currentLanguage);
    }

    // Trier les cartes après filtrage
    const sortedCards = sortCards(
      filtered,
      primarySortMode,
      secondarySortMode,
      secondarySortDirection,
      currentLanguage
    );

    setFilteredCards(sortedCards);
  }, [
    cardTypes,
    cardRarities,
    factionFilters,
    cards,
    nameFilter,
    collectionFilterValue,
    setFilteredCards,
    collectionFilterMode,
    showCollectionFilter,
    secondarySortDirection,
    primarySortMode,
    secondarySortMode,
    currentLanguage,
    version,
  ]);

  const handleOpenDonationPages = () => {
    cards.forEach((card) => {
      window.open(
        `https://www.altered.gg/fr-fr/cards/${card.reference}`,
        "_blank"
      );
    });
  };

  const handleNavigateTo = () => {
    if (goTo) window.open(goTo);
  };

  const handleRemoveFilter = (type: string, value: string) => {
    switch (type) {
      case "type":
        setCardTypes(cardTypes.filter((t) => t !== value));
        break;
      case "rarity":
        setCardRarities(cardRarities.filter((r) => r !== value));
        break;
      case "faction":
        setFactionFilters(factionFilters.filter((f) => f !== value));
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-full space-y-16">
      <div className="relative space-y-2 md:space-y-0">
        <div className="flex justify-between items-end">
          <span className="text-darkBlue hidden lg:block font-chillaxMedium whitespace-nowrap">
            {showCollectionFilter
              ? t("status.onScreenCardsCollection", {
                  nb: getTotalNumberOfCards(filteredCards),
                  diff: filteredCards.length,
                })
              : t("status.onScreenCards", {
                  nb: filteredCards.length,
                })}
          </span>

          <div className="flex justify-center items-center w-full h-full">
            <div className="flex w-fit rounded lg:absolute -bottom-3 right-0 justify-around">
              <div className="flex items-end">
                <NameFilterInput
                  nameFilter={nameFilter}
                  setNameFilter={setNameFilter}
                  smallFilter={smallFilter || false}
                  placeholder={t("filterTitle.name")}
                />
                {!smallFilter && (
                  <Button onClick={openModal} right>
                    <div className="flex justify-center items-center">
                      <IoFilterSharp className="mr-1" />
                      {t("actions.filters")}
                    </div>
                  </Button>
                )}
              </div>
              <ButtonGroup
                isExport={isExport || false}
                isDonation={isDonation || false}
                isClose={isClose || false}
                goTo={goTo}
                handleOpenDonationPages={handleOpenDonationPages}
                handleClose={handleClose}
                handleNavigateTo={handleNavigateTo}
                filteredCards={filteredCards}
              />
              <FilterModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                showCollectionFilter={showCollectionFilter}
                cardTypes={cardTypes}
                setCardTypes={setCardTypes}
                cardRarities={cardRarities}
                setCardRarities={setCardRarities}
                factionFilters={factionFilters}
                setFactionFilters={setFactionFilters}
                collectionFilterValue={collectionFilterValue}
                setCollectionFilterValue={setCollectionFilterValue}
                collectionFilterMode={collectionFilterMode}
                setCollectionFilterMode={setCollectionFilterMode}
                primarySortMode={primarySortMode}
                setPrimarySortMode={setPrimarySortMode}
                version={version}
                setVersion={setVersion}
                secondarySortMode={secondarySortMode}
                setSecondarySortMode={setSecondarySortMode}
                secondarySortDirection={secondarySortDirection}
                setSecondarySortDirection={setSecondarySortDirection}
              />
            </div>
          </div>
        </div>
        <Separator desktop />
        <div className="absolute flex space-x-0.5 w-full overflow-x-auto h-fit flex-nowrap pb-2">
          <div className="inline-flex">
            <ActiveFilters
              filters={{ type: "faction", values: factionFilters }}
              onRemoveFilter={handleRemoveFilter}
            />
          </div>
          <div className="inline-flex">
            <ActiveFilters
              filters={{ type: "type", values: cardTypes }}
              onRemoveFilter={handleRemoveFilter}
            />
          </div>
          <div className="inline-flex">
            <ActiveFilters
              filters={{ type: "rarity", values: cardRarities }}
              onRemoveFilter={handleRemoveFilter}
            />
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <span className="text-darkBlue font-chillaxMedium">
          {showCollectionFilter
            ? t("status.onScreenCardsCollection", {
                nb: getTotalNumberOfCards(filteredCards),
                diff: filteredCards.length,
              })
            : t("status.onScreenCards", {
                nb: filteredCards.length,
              })}
        </span>
        <Separator />
      </div>
    </div>
  );
};

export default CardFilters;
