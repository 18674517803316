import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CardList from "../parts/CardList";
import useSealedSimulator from "../hooks/useSealedSimulator";
import useFetchCards from "../hooks/useFetchCards";
import SealedActions from "../parts/SealedActions";
import Tabs from "../parts/Tabs";
import { TABS } from "../constants/constants";
import Loading from "../parts/Loading";
import Separator from "../components/Separator";
import Layout from "../parts/Layout";
import cardsData from "../data/cards.json";

const Sealed: React.FC = () => {
  const { t } = useTranslation();
  const { sortedCards, loading, error } = useFetchCards(cardsData);
  const [selectedTab, setSelectedTab] = useState<string>(TABS.DRAW.key);
  const [nbBooster, setNbBooster] = useState<number>(7);
  const {
    opened,
    openedCards,
    deckCards,
    handleOpenPacks,
    resetPacks,
    addDeckCard,
    addCardBack,
  } = useSealedSimulator(sortedCards, nbBooster);

  if (loading) {
    return <Loading label={t("loading.loadingCards")} />;
  }

  if (error) {
    return <p className="text-xl text-red-500">{error}</p>;
  }

  const selectView = () => {
    if (selectedTab === TABS.DRAW.key) {
      return (
        <CardList cards={openedCards} isSealed addDeckCard={addDeckCard} />
      );
    } else if (selectedTab === TABS.DECK.key) {
      return <CardList cards={deckCards} isSealed addCardBack={addCardBack} />;
    }
  };

  document.title = t("navigation.sealedGenerator") + " | Altered Card";

  return (
    <Layout>
      <div className="space-y-4 lg:space-y-8 pt-6 lg:pt-10">
        <div className="p-4">
          <SealedActions
            opened={opened}
            deckCards={deckCards}
            handleOpenPacks={handleOpenPacks}
            resetPacks={resetPacks}
            nbBooster={nbBooster}
            setNbBooster={setNbBooster}
          />
        </div>
        {opened && (
          <>
            <Tabs
              tabs={TABS}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              isSealed
            />
            <Separator desktop />
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div className="lg:hidden">{selectView()}</div>
              <div className="hidden lg:block">
                <CardList
                  cards={openedCards}
                  isSealed
                  addDeckCard={addDeckCard}
                />
              </div>
              <div className="border-l-4 border-darkBlue hidden lg:block">
                <CardList
                  cards={deckCards}
                  isSealed
                  isDeck
                  addCardBack={addCardBack}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Sealed;
