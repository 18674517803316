import React from "react";
import { useTranslation } from "react-i18next";
import { getNavigateUrl } from "../utils/getNavigateUrl";
import { LANGUAGES } from "../constants/constants";
import { FiArrowRight } from "react-icons/fi";
import Button from "../components/Button";
import SeparatorVertical from "../components/SeparatorVertical";
import Separator from "../components/Separator";

interface ToolsCardProps {
  title: string;
  desc: string;
  label?: string;
  page?: string;
  mediaSrc?: string;
  mediaType?: "image" | "video";
}

const ToolsCard: React.FC<ToolsCardProps> = ({
  title,
  desc,
  label,
  page,
  mediaSrc,
  mediaType,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  return (
    <div className="flex flex-col md:flex-row justify-center items-center md:min-h-96 rounded-lg shadow-lg p-2 py-4 md:p-6 relative space-y-6 md:space-y-0 md:space-x-10 bg-lightBlue border-4 border-darkBlue">
      <div
        className={`${
          mediaSrc ? "md:w-1/2" : "w-full"
        } h-full flex flex-col justify-between space-y-4 text-center md:text-left`}
      >
        <div className="space-y-4">
          <h3 className="text-2xl font-chillaxBold">{title}</h3>
          <p className="text-lg font-chillaxMedium">{desc}</p>
        </div>
        {page && (
          <div className="w-full md:w-fit">
            <Button isLink to={getNavigateUrl(currentLanguage, page)}>
              <div className="flex justify-center items-center w-fit">
                {label}
                <FiArrowRight className="ml-2 transition-transform duration-300 transform group-hover:translate-x-1" />
              </div>
            </Button>
          </div>
        )}
      </div>
      {mediaSrc && mediaType && (
        <>
          <div className="h-72 hidden md:block">
            <SeparatorVertical />
          </div>
          <div className="w-full h-1 md:hidden">
            <Separator />
          </div>
          <div className="md:w-1/2 flex items-center justify-center">
            {mediaType === "image" ? (
              <img
                src={mediaSrc}
                alt={title}
                className="rounded-lg max-h-64 object-cover"
              />
            ) : (
              <video
                src={mediaSrc}
                autoPlay
                playsInline
                loop
                muted
                preload="auto"
                className="rounded-lg max-h-80 object-cover"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ToolsCard;
