import { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineDotsHorizontal, HiOutlineDotsVertical } from "react-icons/hi";
import {
  toggleSidePanel,
  setColumnSelectionEnabled,
  setColumns,
  toggleFoil,
  setRareQuantity,
  setCommonQuantity,
  setCardsPerPage,
  toggleFoilSidePanelCloseDefault,
} from "../store/slices/sidePanelSlice";
import { RootState } from "../store/store";
import Separator from "../components/Separator";

interface SidePanelOptionsProps {
  handleShareCollection?: () => void;
  handleSelectCards?: () => void;
}

const SidePanelOptions = ({
  handleShareCollection,
  handleSelectCards,
}: SidePanelOptionsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSidePanelOpen = useSelector(
    (state: RootState) => state.sidePanel.isSidePanelOpen
  );
  const isColumnSelectionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isColumnSelectionEnabled
  );
  const isSidePanelCloseDefault = useSelector(
    (state: RootState) => state.sidePanel.isSidePanelCloseDefault
  );
  const columns = useSelector((state: RootState) => state.sidePanel.columns);
  const isFoilEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFoilEnabled
  );
  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );
  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );
  const cardsPerPage = useSelector(
    (state: RootState) => state.sidePanel.cardsPerPage
  );

  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isSidePanelOpen) {
      timeout = setTimeout(() => setIsContentVisible(true), 150);
    } else {
      setIsContentVisible(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isSidePanelOpen]);

  return (
    <div
      className={`fixed left-0 transition-all duration-300 z-40 pt-16 pb-8 ${
        isSidePanelOpen ? "w-full md:w-1/5" : "w-0"
      } shadow-xl overflow-hidden border-r-4 border-darkBlue bg-beige`}
      style={{ height: "100vh" }}
    >
      {!isSidePanelOpen ? (
        <>
          <button
            onClick={() => dispatch(toggleSidePanel())}
            className="md:hidden group fixed w-1/3 flex justify-center right-1/2 translate-x-1/2 bottom-0 p-3 py-0.5 z-50 bg-darkBlue text-white text-xl rounded-t-md"
          >
            <HiOutlineDotsHorizontal />
          </button>

          <button
            onClick={() => dispatch(toggleSidePanel())}
            className="hidden md:block group fixed top-1/2 h-1/4 -left-3 md:-left-4 transform -translate-y-1/2 py-3 p-1 z-50 bg-darkBlue text-white text-xl rounded-r-md transition-all duration-300 hover:left-0"
          >
            <HiOutlineDotsVertical className="group-hover:scale-110 transition-transform duration-300" />
          </button>
        </>
      ) : (
        isContentVisible && (
          <div className="relative flex flex-col space-y-10 md:justify-between h-full pt-10 pb-20 md:pr-10 p-4 md:pt-4 md:pb-4 font-chillaxMedium text-xs md:text-base overflow-y-auto">
            <div className="space-y-2 md:space-y-4">
              {handleShareCollection && (
                <>
                  <div className="space-y-2">
                    <p>{t("sidePanel.selectNumber")}</p>
                    <div className="flex w-full space-x-4">
                      <div className="flex flex-col">
                        <p className="font-chillaxBold">
                          {t("gameElements.rares")}
                        </p>
                        <input
                          type="number"
                          min="1"
                          value={rareQuantity || 3}
                          onChange={(e) =>
                            dispatch(setRareQuantity(Number(e.target.value)))
                          }
                          className="block w-full max-w-16 shadow sm:text-sm font-chillaxBold rounded-md h-9 p-2 outline-none border-4 bg-lightBlue border-darkBlue"
                        />
                      </div>

                      <div className="flex flex-col">
                        <p className="font-chillaxBold">
                          {t("gameElements.commons")}
                        </p>
                        <input
                          type="number"
                          min="1"
                          value={commonQuantity || 9}
                          onChange={(e) =>
                            dispatch(setCommonQuantity(Number(e.target.value)))
                          }
                          className="block w-full max-w-24 shadow sm:text-sm font-chillaxBold rounded-md h-9 p-2 outline-none border-4 bg-lightBlue border-darkBlue"
                        />
                      </div>
                    </div>
                  </div>
                  <Separator />
                </>
              )}
              <div className="space-y-2">
                <p>{t("sidePanel.paramsDisplay")}</p>
                <div className="flex flex-col">
                  <label htmlFor="cardsPerPage" className="font-chillaxBold">
                    {t("sidePanel.cardsPerPage")}
                  </label>
                  <select
                    id="cardsPerPage"
                    value={cardsPerPage || 10}
                    onChange={(event) =>
                      dispatch(setCardsPerPage(parseInt(event.target.value)))
                    }
                    className="w-36 shadow sm:text-sm font-chillaxBold rounded-md p-2 outline-none border-4 bg-lightBlue border-darkBlue"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={isColumnSelectionEnabled}
                    onChange={() =>
                      dispatch(
                        setColumnSelectionEnabled(!isColumnSelectionEnabled)
                      )
                    }
                    className="form-checkbox outline-none accent-darkBlue"
                  />
                  <span className="font-chillaxBold">
                    {t("sidePanel.columns", {
                      columns: isColumnSelectionEnabled
                        ? `(${columns || 4})`
                        : "",
                    })}
                  </span>
                </label>
                {isColumnSelectionEnabled && (
                  <input
                    type="range"
                    min="1"
                    max="10"
                    value={columns || 4}
                    onChange={(e) =>
                      dispatch(setColumns(Number(e.target.value)))
                    }
                    className="custom-slider"
                  />
                )}
              </div>
              <Separator />
              <div className="space-y-2">
                <p>{t("sidePanel.descFoil")}</p>

                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={isFoilEnabled}
                    onChange={() => dispatch(toggleFoil())}
                    className="form-checkbox outline-none accent-darkBlue"
                  />
                  <span className="font-chillaxBold">
                    {t("sidePanel.enableFoil")}
                  </span>
                </label>
              </div>
              <Separator />
              <div className="space-y-2">
                <p>{t("sidePanel.descSidePanelDefault")}</p>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={isSidePanelCloseDefault}
                    onChange={() => dispatch(toggleFoilSidePanelCloseDefault())}
                    className="form-checkbox outline-none accent-darkBlue"
                  />
                  <span className="font-chillaxBold">
                    {t("sidePanel.sidePanelCloseDefault")}
                  </span>
                </label>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center space-y-2">
              {handleSelectCards && (
                <Button onClick={handleSelectCards} full>
                  {t("actions.selectCards")}
                </Button>
              )}
              {handleShareCollection && (
                <Button onClick={handleShareCollection} full>
                  {t("actions.shareCards")}
                </Button>
              )}
              <div className="md:hidden w-full space-y-2">
                <Separator />
                <Button onClick={() => dispatch(toggleSidePanel())} full>
                  {t("actions.close")}
                </Button>
              </div>
            </div>
            <button
              onClick={() => dispatch(toggleSidePanel())}
              className="hidden md:block absolute top-1/2 transform -translate-y-1/2 right-0 py-3 p-1 rounded-l-md bg-darkBlue text-white text-xl"
            >
              <CgClose />
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default SidePanelOptions;
