import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import { CardType } from "../types/CardType";
import { LANGUAGES } from "../constants/constants";

const randomRotation = () => `${Math.floor(Math.random() * 30) - 10}deg`;

interface CardSliderProps {
  cards: CardType[];
}

const CardSlider: React.FC<CardSliderProps> = ({ cards }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;
  const [printCards, setPrintCards] = useState<any[]>(
    cards.map((card) => ({
      ...card,
      rotateAngle: randomRotation(),
    }))
  );

  useEffect(() => {
    const lcpImage = cards[0]?.imagePath;
    if (lcpImage) {
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = lcpImage[currentLanguage];
      link.as = "image";
      document.head.appendChild(link);
    }
  }, [cards, currentLanguage]);

  const handleNext = () => {
    setPrintCards((prevCards) => {
      const [firstCard, ...rest] = prevCards;
      return [...rest, { ...firstCard, rotateAngle: randomRotation() }];
    });
  };

  const bringToFront = (index: number) => {
    if (index === 0) {
      handleNext();
      return;
    }
    setPrintCards((prevCards) => {
      const selectedCard = prevCards[index];
      const otherCards = prevCards.filter((_, i) => i !== index);
      const [firstCard, ...remainingCards] = otherCards;

      return [
        { ...selectedCard },
        ...remainingCards,
        { ...firstCard, rotateAngle: randomRotation() },
      ];
    });
  };

  return (
    <div className="relative w-full h-full overflow-hidden">
      <div className="relative w-full h-full">
        {printCards.map((card, index) => (
          <div
            key={card.reference}
            onClick={() => bringToFront(index)}
            className={`absolute inset-0 m-auto transition-transform duration-500 ease-in-out transform w-fit h-fit cursor-pointer`}
            style={{
              transform: `rotate(${card.rotateAngle}) translateX(${
                index === 0 ? "-10%" : "0%"
              })`,
              opacity: 1,
              zIndex: printCards.length - index,
            }}
          >
            <div className="w-52 md:w-72">
              <Card card={card} noClick />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSlider;
