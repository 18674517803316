import { useState } from "react";

export const useSharedCollection = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const createSharedCollection = async (cards, missingCards, excessCards, user) => {
    setIsLoading(true)
    setError(null)

    const pseudo = user?.pseudo || `Player-${Math.floor(Math.random() * 10000)}`;

    const response = await fetch('https://www.api.altered-card.com/api/sharedCollection', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ cards, missingCards, excessCards, pseudo })
    })
    const json = await response.json()

    if (!response.ok) {
      setIsLoading(false)
      setError(json.error)
    }
    if (response.ok) {
      setIsLoading(false)
      return json
    }
  }

  const getSharedCollection = async (id) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://www.api.altered-card.com/api/sharedCollection/' + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const json = await response.json();

      if (!response.ok) {
        setIsLoading(false);
        setError(json.error);
        return null;
      }

      setIsLoading(false);
      return json;

    } catch (error) {
      setIsLoading(false);
      setError('Une erreur s\'est produite lors de la récupération de la collection');
      return null;
    }
  };


  return { createSharedCollection, getSharedCollection, isLoading, error }
}