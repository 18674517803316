import React from "react";
import { useTranslation } from "react-i18next";
import { RARITY } from "../constants/constants";
import { cardsRarityData } from "../data/filtersData";

interface RaritySelectProps {
  selectedRarities: string[];
  onChange: (selectedRarities: string[]) => void;
  isUnique?: boolean;
}

const RaritySelect: React.FC<RaritySelectProps> = ({
  selectedRarities,
  onChange,
  isUnique = false,
}) => {
  const { t } = useTranslation();

  const handleCheckboxChange = (rarity: string) => {
    const isSelected = selectedRarities.includes(rarity);
    const updatedRarities = isSelected
      ? selectedRarities.filter((r) => r !== rarity)
      : [...selectedRarities, rarity];
    onChange(updatedRarities);
  };

  const rarityOptions = cardsRarityData.filter((rarityData) =>
    isUnique ? true : rarityData.value !== RARITY.UNIQUE
  );

  return (
    <div className="space-y-2">
      <div className="flex space-x-2">
        {rarityOptions.map((rarityData) => (
          <div key={rarityData.value} className="flex items-center space-x-1">
            <input
              type="checkbox"
              checked={selectedRarities.includes(rarityData.value)}
              onChange={() => handleCheckboxChange(rarityData.value)}
              id={`rarity-${rarityData.value}`}
              className="form-checkbox outline-none accent-darkBlue"
            />
            <label
              htmlFor={`rarity-${rarityData.value}`}
              className="font-chillaxMedium"
            >
              {t(rarityData.label)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RaritySelect;
