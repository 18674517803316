import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useLocation } from "react-router-dom";
import { PiArrowsLeftRightFill } from "react-icons/pi";
import { parseCardList, calculateExchanges } from "../utils/cardUtilities";
import CardList from "../parts/CardList";
import { CardType, Exchange } from "../types/CardType";
import useFetchCards from "../hooks/useFetchCards";
import { TABS_EXCHANGE } from "../constants/constants";
import { useTranslation, Trans } from "react-i18next";
import Button from "../components/Button";
import Tabs from "../parts/Tabs";
import CardSelectionModal from "../parts/CardSelectionModal";
import { linkTo } from "../utils/linkTo";
import Layout from "../parts/Layout";
import cardsData from "../data/cards.json";

interface ExtendedCardType extends CardType {
  inMyCollection: number;
}

const buildCardsFromExchanges = (
  exchangesCards: Exchange[],
  allCards?: CardType[]
): ExtendedCardType[] => {
  return exchangesCards
    .map((exchangesCard) => {
      const card = allCards?.find(
        (card) => card.reference === exchangesCard.card
      );
      if (card) {
        return {
          ...card,
          inMyCollection: exchangesCard.count,
        };
      }
      return undefined;
    })
    .filter((card): card is ExtendedCardType => card !== undefined);
};

const CardExchange = () => {
  const { t } = useTranslation();
  const { allCards } = useFetchCards(cardsData);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialListA = queryParams.get("list");
  const decodedListA = initialListA ? decodeURIComponent(initialListA) : "";

  const [list1, setList1] = useState(decodedListA);
  const [list2, setList2] = useState("");
  const [cardsNeeded, setCardsNeeded] = useState(3);
  const [exchangeMessage, setExchangeMessage] = useState<string>("");
  const [exchanges, setExchanges] = useState<Exchange[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>(
    TABS_EXCHANGE.PLAYER1.key
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCards, setSelectedCards] = useState<CardType[]>([]);

  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );

  const handleCalculate = () => {
    const cards1 = parseCardList(list1);
    const cards2 = parseCardList(list2);
    const result = calculateExchanges(cards1, cards2, cardsNeeded);
    setExchanges(result);
    if (!(result.length > 0)) {
      setExchangeMessage(t("exchanges.noExchangeAvailable"));
    } else {
      setExchangeMessage("");
    }
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleCardSelection = (cards: CardType[]) => {
    setSelectedCards(cards);
    setList1(
      cards.map((card) => `${card.nbSelected} ${card.reference}`).join("\n")
    );
  };

  const exchangesFrom1To2 = exchanges.filter(
    (exchange) => exchange.from === "1" && exchange.to === "2"
  );
  const exchangesFrom2To1 = exchanges.filter(
    (exchange) => exchange.from === "2" && exchange.to === "1"
  );

  const cardsToSendFromA = buildCardsFromExchanges(exchangesFrom1To2, allCards);
  const cardsToSendFromB = buildCardsFromExchanges(exchangesFrom2To1, allCards);

  document.title = t("navigation.simExchange") + " | Altered Card";

  return (
    <Layout>
      <div className="flex flex-col space-y-4 justify-center items-center">
        <div className="flex items-end">
          <div className="w-full relative">
            <label
              htmlFor="desiredFilter"
              className="absolute -top-5 left-1 text-sm font-chillaxMedium text-darkBlue whitespace-nowrap"
            >
              {t("actions.desiredQuantity")}
            </label>
            <input
              type="number"
              id="nbCard"
              value={cardsNeeded}
              onChange={(e) => setCardsNeeded(Number(e.target.value))}
              className="block w-full max-w-36 shadow sm:text-sm rounded-l-md rounded-r-none h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
            />
          </div>
          <Button onClick={handleCalculate} right>
            {t("actions.validate")}
          </Button>
        </div>
        <div className="flex space-x-2 justify-center w-full lg:px-8">
          <div className="flex flex-col justify-center w-full">
            <textarea
              rows={7}
              placeholder={t("exchanges.enterExchangeCards", { player: "1" })}
              value={list1}
              onChange={(e) => setList1(e.target.value)}
              className="block w-full shadow text-[8px] sm:text-sm p-1 rounded-t-md rounded-b-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
            />
            <Button onClick={handleOpenModal} bottom>
              {t("actions.selectCards")}
            </Button>
          </div>
          <div className="flex h-auto justify-center items-center text-xl md:text-3xl text-darkBlue">
            <PiArrowsLeftRightFill />
          </div>
          <textarea
            rows={7}
            placeholder={t("exchanges.enterExchangeCards", { player: "2" })}
            value={list2}
            onChange={(e) => setList2(e.target.value)}
            className="block w-full shadow text-[8px] sm:text-sm p-1 rounded-r-md rounded-l-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
          />
        </div>
      </div>
      <div className="flex justify-center items-center font-chillaxMedium bottom-0 px-2 md:px-8 w-full text-center">
        <span>
          <Trans
            i18nKey="navigation.tutoDiscord"
            components={{
              LinkTo: linkTo({
                dest: "https://discord.gg/8yvbc3bk8h",
              }),
              Bold: <strong />,
            }}
          />
        </span>
      </div>
      {(cardsToSendFromA.length > 0 || cardsToSendFromB.length > 0) && (
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={TABS_EXCHANGE}
        />
      )}
      {(cardsToSendFromA.length > 0 || cardsToSendFromB.length > 0) && (
        <div>
          {selectedTab === TABS_EXCHANGE.PLAYER1.key && cardsToSendFromA && (
            <div className="flex w-full justify-center items-center">
              {cardsToSendFromA.length > 0 ? (
                <CardList cards={cardsToSendFromA} isExchange isExport />
              ) : (
                <p className="font-chillaxBold text-xl">
                  {t("exchanges.noneExchange")}
                </p>
              )}
            </div>
          )}

          {selectedTab === TABS_EXCHANGE.PLAYER2.key && cardsToSendFromB && (
            <div className="flex w-full justify-center items-center">
              {cardsToSendFromB.length > 0 ? (
                <CardList cards={cardsToSendFromB} isExchange isExport />
              ) : (
                <p className="font-chillaxBold text-xl">
                  {t("exchanges.noneExchange")}
                </p>
              )}
            </div>
          )}
        </div>
      )}

      {exchangeMessage && (
        <div className="w-full flex justify-center">
          <span className="font-chillaxBold text-xl">{exchangeMessage}</span>
        </div>
      )}

      <CardSelectionModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        cards={myCards.length > 0 ? myCards : allCards || []}
        selectedCards={selectedCards}
        setSelectedCards={handleCardSelection}
        quantity
      />
    </Layout>
  );
};

export default CardExchange;
