import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import useFetchCards from "../hooks/useFetchCards";
import Loading from "../parts/Loading";
import { Trans, useTranslation } from "react-i18next";
import { UserFriendship, CardType, CardStatsInfo } from "../types/CardType";
import { getMissingCards } from "../utils/cardUtilities";
import CardList from "../parts/CardList";
import Modal from "@mui/material/Modal";
import FriendFilter from "../parts/FriendFilters";
import Layout from "../parts/Layout";
import cardsData from "../data/cards.json";

const Friends: React.FC = () => {
  const { t } = useTranslation();
  const { allCards, loading, error } = useFetchCards(cardsData);
  const [selectedTradeCards, setSelectedTradeCards] = useState<CardType[]>();
  const [selectedFriend, setSelectedFriend] = useState<UserFriendship>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );
  const myFriends = useSelector((state: RootState) => state.friends.friends);
  const [filteredFriends, setFilteredFriends] =
    useState<UserFriendship[]>(myFriends);

  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );

  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );

  const missingCards = useMemo(
    () =>
      myCards &&
      allCards &&
      getMissingCards(myCards, allCards, rareQuantity, commonQuantity),
    [myCards, allCards, rareQuantity, commonQuantity]
  );

  const enrichedFriends = useMemo(() => {
    return myFriends.map((friendship) => {
      const matchingCards = friendship.cards?.filter((card) =>
        missingCards?.some(
          (missingCard) => missingCard.reference === card.reference
        )
      );

      return {
        ...friendship,
        tradeAvailable: matchingCards ? matchingCards.length : 0,
        matchingCards: matchingCards || [],
      };
    });
  }, [myFriends, missingCards]);

  const handleTradeClick = (
    friendship: UserFriendship,
    matchingCards?: CardStatsInfo[]
  ) => {
    if (allCards) {
      const tradeCards =
        matchingCards &&
        (matchingCards
          .map((matchingCard) => {
            const card = allCards.find(
              (c) => c.reference === matchingCard.reference
            );
            if (card) {
              return {
                ...card,
                inMyCollection: matchingCard.inMyCollection,
                quantity: matchingCard.quantity,
              };
            }
            return null;
          })
          .filter((card) => card !== null) as CardType[]);

      setSelectedTradeCards(tradeCards);
      setSelectedFriend(friendship);
      setIsModalOpen(true);
    }
  };

  const handleFilterChange = (filtered: UserFriendship[]) => {
    setFilteredFriends([...filtered]);
  };

  document.title = "Friendships | Altered Card";

  if (loading) {
    return <Loading label={t("loading.loadingFriends")} />;
  }

  if (error) {
    return <p className="text-xl text-red-500">{error}</p>;
  }

  return (
    <Layout>
      <FriendFilter
        friends={enrichedFriends}
        filteredFriends={filteredFriends}
        setFilteredFriends={handleFilterChange}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
        {filteredFriends.length > 0 ? (
          filteredFriends.map((friendship) => (
            <div
              key={friendship.friendshipId}
              className={`p-4 bg-white shadow-md rounded-md border border-gray-200 text-darkBlue space-y-2 cursor-pointer`}
              onClick={() =>
                friendship.tradeAvailable
                  ? handleTradeClick(friendship, friendship.matchingCards)
                  : window.open(
                      `https://www.altered.gg/profile/${friendship?.userFriend.uniqueId}`
                    )
              }
            >
              <h2 className="text-xl font-chillaxBold">
                {friendship.userFriend.nickName}
              </h2>
              <div>
                <p className="font-chillaxMedium">
                  <span>
                    <Trans
                      i18nKey="friendship.totalcards"
                      values={{
                        totalcards: friendship.cards
                          ? friendship.cards.length
                          : 0,
                      }}
                      components={{
                        Bold: <strong />,
                      }}
                    />
                  </span>
                </p>
                <p className="font-chillaxMedium">
                  <span>
                    <Trans
                      i18nKey="friendship.lastUpdate"
                      values={{
                        date: new Date(friendship.lastUpdate).toLocaleString(
                          undefined,
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        ),
                      }}
                      components={{
                        Bold: <strong />,
                      }}
                    />
                  </span>
                </p>
              </div>
              {friendship.tradeAvailable !== undefined &&
                friendship.tradeAvailable > 0 && (
                  <p className="text-green-600 font-chillaxBold">
                    {t("friendship.availableTrade", {
                      availableTrade: friendship.tradeAvailable,
                    })}
                  </p>
                )}
            </div>
          ))
        ) : (
          <p className="text-gray-500">{t("friendship.noFriendsFound")}</p>
        )}
      </div>

      {isModalOpen && selectedTradeCards && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-75 z-[1000]"
        >
          <div className="bg-beige p-4 rounded shadow-lg lg:w-2/3 h-5/6 space-y-4 overflow-scroll relative border-4 border-darkBlue">
            <CardList
              cards={selectedTradeCards}
              goTo={`https://www.altered.gg/profile/${selectedFriend?.userFriend.uniqueId}`}
              isExport
            />
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default Friends;
