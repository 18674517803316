import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import "./css/chillax.css";
import "@mountainpass/react-pokemon-cards/dist/css/cards.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Provider } from "react-redux";
import store, { persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { AuthContextProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
document.documentElement.lang = i18n.language;
i18n.on("languageChanged", (lng) => (document.documentElement.lang = lng));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthContextProvider>
          <I18nextProvider i18n={i18n}>
            <App />
            <ToastContainer position="bottom-right" />
          </I18nextProvider>
        </AuthContextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
