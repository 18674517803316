import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import CardList from "../parts/CardList";
import { CardStatsInfo, CardType } from "../types/CardType";
import Button from "../components/Button";
import { useTranslation, Trans } from "react-i18next";
import useFetchCards from "../hooks/useFetchCards";
import CardSelectionModal from "../parts/CardSelectionModal";
import { linkTo } from "../utils/linkTo";
import Layout from "../parts/Layout";
import cardsData from "../data/cards.json";
import { useCardInput } from "../hooks/useCardInput";
import { createMyCardsFromText } from "../utils/formatCards";
import { TABS_DECKS } from "../constants/constants";
import Tabs from "../parts/Tabs";
import { getMissingDeckCards } from "../utils/cardUtilities";

const Decks = () => {
  const { t } = useTranslation();
  const { allCards } = useFetchCards(cardsData);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deckCards, setDeckCards] = useState<CardType[]>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<string>(TABS_DECKS.DECK.key);

  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );

  const missingCards =
    myCards && deckCards && getMissingDeckCards(myCards, deckCards);

  const {
    cardInput,
    selectedCards,
    handleCardInputChange,
    setSelectedCards,
    setCardInput,
  } = useCardInput({
    allCards,
  });

  const handleCloseModal = () => {
    const selectedCardIds = selectedCards
      .map((card) => `${card.nbSelected || 1} ${card.reference}`)
      .join("\n");
    setCardInput(selectedCardIds);
    setModalIsOpen(false);
  };

  const handleCardInput = () => {
    const cardEntries = cardInput.split("\n");
    const cardData: CardStatsInfo[] = cardEntries.map((entry) => {
      const [count, reference] = entry.split(" ");
      return {
        reference,
        inMyCollection: parseInt(count, 10) || 0,
      } as CardStatsInfo;
    });

    if (allCards) {
      const { includedCards } = createMyCardsFromText(allCards, cardData);
      if (includedCards.length > 0) {
        setDeckCards(includedCards);
        setErrorMessage(undefined);
      } else {
        setErrorMessage(t("errors.invalidCardList"));
        setDeckCards([]);
      }
    }
  };

  document.title = t("navigation.decks") + " | Altered Card";

  return (
    <Layout handleSelectCards={() => setModalIsOpen(true)}>
      <div className="space-y-10">
        <div className="flex justify-center items-center w-full">
          <div className="w-full items-start px-6 lg:px-20">
            <div className="flex flex-row space-x-2 justify-center items-center">
              <div className="flex justify-center w-full">
                <textarea
                  rows={4}
                  placeholder={t("actions.enterYourDeckList")}
                  value={cardInput}
                  onChange={handleCardInputChange}
                  className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                />
                <Button onClick={handleCardInput} isVertical right>
                  {t("actions.validate")}
                </Button>
              </div>
            </div>
            {/* Discord Link */}
            <div className="flex justify-center items-center font-chillaxMedium bottom-0 px-2 md:px-8 w-full text-center">
              <span>
                <Trans
                  i18nKey="navigation.tutoDiscord"
                  components={{
                    LinkTo: linkTo({
                      dest: "https://discord.gg/8yvbc3bk8h",
                    }),
                    Bold: <strong />,
                  }}
                />
              </span>
            </div>
            {/* Error Message */}
            {errorMessage && (
              <div className="flex justify-center">
                <span className="text-red-600">{errorMessage}</span>
              </div>
            )}
          </div>
        </div>
        {deckCards && (
          <Tabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabs={TABS_DECKS}
          />
        )}
        {deckCards && deckCards.length > 0 && (
          <>
            {selectedTab === TABS_DECKS.DECK.key && (
              <CardList cards={deckCards} />
            )}
            {selectedTab === TABS_DECKS.MISSING.key && missingCards && (
              <CardList cards={missingCards} />
            )}
          </>
        )}
      </div>

      <CardSelectionModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        cards={allCards || []}
        selectedCards={selectedCards}
        setSelectedCards={setSelectedCards}
        myCollection={myCards}
        quantity
      />
    </Layout>
  );
};

export default Decks;
