import React from "react";
import { useTranslation } from "react-i18next";
import Separator from "../components/Separator";

interface Tab {
  key: string;
  label: string;
}

interface TabsProps<T extends { [key: string]: Tab }> {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  tabs: T;
  isSealed?: boolean;
  small?: boolean;
}

const Tabs = <T extends { [key: string]: Tab }>({
  selectedTab,
  setSelectedTab,
  tabs,
  isSealed,
  small,
}: TabsProps<T>) => {
  const { t } = useTranslation();
  return (
    <div className={`block ${isSealed ? "lg:hidden" : ""}`}>
      <div
        className={`flex lg:px-10 md:space-x-2 ${
          small ? "space-x-1 px-1" : "space-x-2 px-2"
        }`}
      >
        {Object.values(tabs).map((tab) => (
          <button
            key={tab.key}
            className={`${
              small ? "px-1" : "px-4"
            } w-full rounded-t-xl border-4 py-2 border-b-0 font-chillaxBold text-sm lg:text-base border-darkBlue ${
              selectedTab === tab.key
                ? "bg-darkBlue text-white cursor-default"
                : "text-darkBlue hover:bg-lightBlue"
            }`}
            onClick={() => setSelectedTab(tab.key)}
          >
            {t(tab.label)}
          </button>
        ))}
      </div>
      <Separator />
    </div>
  );
};

export default Tabs;
