import { useState, useEffect } from "react";
import { CardType } from "../types/CardType";
import { useTranslation } from "react-i18next";
import { CARD_TYPE } from "../constants/constants";

type ValidationResult = {
  isValid: boolean;
  errors: string[];
};

const useDeckValidation = (
  cards: CardType[],
  minCards: number,
  maxFactions: number,
  nbHeroes: number
): ValidationResult => {
  const [validation, setValidation] = useState<ValidationResult>({
    isValid: false,
    errors: [],
  });
  const { t } = useTranslation();

  useEffect(() => {
    const errors: string[] = [];

    // Vérification du nombre minimum de cartes
    if (cards.length < minCards) {
      errors.push(t("errors.minCardsError", { minCards }));
    }

    // Vérification du nombre minimum de héros
    const numberOfHeroes = cards.filter(
      (card) => card.type === CARD_TYPE.HERO
    ).length;
    if (numberOfHeroes > nbHeroes) {
      errors.push(t("errors.maxHeroesError", { nbHeroes }));
    }

    // Vérification du nombre maximum de factions
    const factionCount = new Set(cards.map((card) => card.mainFaction)).size;
    if (factionCount > maxFactions) {
      errors.push(t("errors.maxFactionsError", { maxFactions }));
    }

    // Mise à jour de l'état de validation
    setValidation({
      isValid: errors.length === 0,
      errors,
    });
  }, [cards, minCards, maxFactions, nbHeroes, t]);

  return validation;
};

export default useDeckValidation;
