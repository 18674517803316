interface LINK_TO_PROPS {
  dest: string;
}

export const linkTo = ({ dest }: LINK_TO_PROPS) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    href={dest}
    className={`font-chillaxBold underline`}
    target="_blank"
    rel="noreferrer"
  />
);
