import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SUPPORTED_LANGUAGE } from "../constants/constants";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    navigate(location.pathname.replace(/\/[a-z]{2}\//, `/${lng}/`));
  };

  return (
    <div className="relative inline-block text-left w-fit font-chillaxMedium bg-darkBlue cursor-pointer">
      <select
        onChange={(e) => changeLanguage(e.target.value)}
        value={i18n.language || SUPPORTED_LANGUAGE[0]}
        className="block w-fit text-xl focus:outline-none rounded-md text-white bg-darkBlue"
      >
        {SUPPORTED_LANGUAGE.map((lang) => (
          <option key={lang} value={lang}>
            {lang.toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
