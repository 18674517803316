import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import CardList from "../parts/CardList";
import useFetchCards from "../hooks/useFetchCards";
import { useTranslation } from "react-i18next";
import Layout from "../parts/Layout";
import Tabs from "../parts/Tabs";
import { TABS_MARKET } from "../constants/constants";
import cardsData from "../data/enriched_unique_cards.json";
import Loading from "../parts/Loading";

const UniqueMarket: React.FC = () => {
  const { t } = useTranslation();
  const { allCards, loading, error } = useFetchCards(cardsData);
  const [selectedTab, setSelectedTab] = useState<string>(
    TABS_MARKET.MARKET.key
  );
  const myFavoriteCard = useSelector(
    (state: RootState) => state.collectionCards.favoriteCards
  );

  if (loading) {
    return <Loading label={t("loading.loadingCards")} />;
  }

  if (error) {
    return <p className="text-xl text-red-500">{error}</p>;
  }

  return (
    <Layout>
      {allCards && allCards.length > 0 ? (
        <div className="space-y-8 text-center">
          <h2 className="font-chillaxBold text-2xl">
            {t("status.lastUpdate", { date: `17-09-2024`, time: "11:00" })}
          </h2>
          <div>
            <Tabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={TABS_MARKET}
            />
            {selectedTab === TABS_MARKET.MARKET.key && (
              <CardList cards={allCards} asFavorite />
            )}
            {selectedTab === TABS_MARKET.FAVORITE.key && (
              <CardList cards={myFavoriteCard} asFavorite />
            )}
          </div>
        </div>
      ) : (
        <p>{t("friendship.noCardsFound")}</p>
      )}
    </Layout>
  );
};

export default UniqueMarket;
