import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CardList from "../parts/CardList";
import { CardType } from "../types/CardType";
import Tabs from "../parts/Tabs";
import { TABS_SHARED_CARDS } from "../constants/constants";
import { useTranslation } from "react-i18next";
import { handleTextToCard } from "../utils/cardUtilities";
import useFetchCards from "../hooks/useFetchCards";
import Layout from "../parts/Layout";
import Loading from "../parts/Loading";
import cardsData from "../data/cards.json";
import { useSharedCollection } from "../hooks/useSharedCollection";

const SharedCollection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const binId = queryParams.get("binId");
  const { allCards } = useFetchCards(cardsData);
  const { getSharedCollection } = useSharedCollection();

  const [pseudo, setPseudo] = useState<string>();
  const [collectionCards, setCollectionCards] = useState<CardType[]>([]);
  const [missingCards, setMissingCards] = useState<CardType[]>([]);
  const [excessCards, setExcessCards] = useState<CardType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("");

  useEffect(() => {
    const fetchSharedCollection = async () => {
      setLoading(true);
      const response = await getSharedCollection(binId);
      if (response) {
        const myCardsText = response.cards || "";
        const missingCardsText = response.missingCards || "";
        const excessCardsText = response.excessCards || "";

        const parsedCollectionCards =
          allCards && handleTextToCard(myCardsText, allCards);
        const parsedMissingCards =
          allCards && handleTextToCard(missingCardsText, allCards);
        const parsedExcessCards =
          allCards && handleTextToCard(excessCardsText, allCards);

        parsedCollectionCards && setCollectionCards(parsedCollectionCards);
        parsedMissingCards && setMissingCards(parsedMissingCards);
        parsedExcessCards && setExcessCards(parsedExcessCards);
        setPseudo(response.pseudo || "");
      }
      setLoading(false);
    };
    if (
      collectionCards.length > 0 ||
      missingCards.length > 0 ||
      excessCards.length > 0
    ) {
      return;
    } else fetchSharedCollection();
  }, [
    binId,
    allCards,
    collectionCards,
    missingCards,
    excessCards,
    getSharedCollection,
  ]);

  const availableTabs = Object.entries(TABS_SHARED_CARDS)
    .filter(([key, _]) => {
      if (
        key === TABS_SHARED_CARDS.COLLECTION.key &&
        collectionCards.length > 0
      )
        return true;
      if (key === TABS_SHARED_CARDS.MISSING.key && missingCards.length > 0)
        return true;
      if (key === TABS_SHARED_CARDS.EXCESS.key && excessCards.length > 0)
        return true;
      return false;
    })
    .reduce((acc, [key, value]) => {
      acc[key as keyof typeof TABS_SHARED_CARDS] = value;
      return acc;
    }, {} as Partial<typeof TABS_SHARED_CARDS>);

  const firstAvailableTab = Object.keys(availableTabs)[0];
  if (!selectedTab && firstAvailableTab) {
    setSelectedTab(firstAvailableTab);
  }

  if (loading) {
    return <Loading label={t("loading.loadingCollection")} />;
  }

  return (
    <Layout>
      <div className="px-6 lg:px-20">
        {Object.keys(availableTabs).length > 0 ? (
          <>
            <h1 className="text-3xl font-chillaxBold text-center mb-8 text-darkBlue">
              {t("shared.title", { PSEUDO: pseudo })}
            </h1>
            <Tabs
              tabs={availableTabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            {selectedTab === TABS_SHARED_CARDS.COLLECTION.key && (
              <CardList cards={collectionCards} showCollectionFilter />
            )}
            {selectedTab === TABS_SHARED_CARDS.MISSING.key && (
              <CardList cards={missingCards} showCollectionFilter />
            )}
            {selectedTab === TABS_SHARED_CARDS.EXCESS.key && (
              <CardList cards={excessCards} showCollectionFilter />
            )}
          </>
        ) : (
          <p className="text-center text-xl font-chillaxMedium">
            {t("errors.noSharedCollection")}
          </p>
        )}
      </div>
    </Layout>
  );
};

export default SharedCollection;
