import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import Footer from "./Footer";
import SidePanelOptions from "./SidePanelOptions";
import SidePanelProfil from "./SidePanelProfil";
import { setSidePanel } from "../store/slices/sidePanelSlice";
import { isMobile } from "react-device-detect";

interface LayoutProps {
  children: ReactNode;
  handleShareCollection?: () => void;
  handleSelectCards?: () => void;
  noSidebar?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  handleShareCollection,
  handleSelectCards,
  noSidebar,
}) => {
  const dispatch = useDispatch();
  const isSidePanelOpen = useSelector(
    (state: RootState) => state.sidePanel.isSidePanelOpen
  );
  const isSidePanelProfilOpen = useSelector(
    (state: RootState) => state.sidePanelProfil.isSidePanelProfilOpen
  );
  const isSidePanelCloseDefault = useSelector(
    (state: RootState) => state.sidePanel.isSidePanelCloseDefault
  );

  const [lastPanelOpened, setLastPanelOpened] = useState<
    "left" | "right" | null
  >(null);

  useEffect(() => {
    if (children) {
      if (isMobile) {
        dispatch(setSidePanel(false));
      } else if (!isSidePanelCloseDefault) {
        dispatch(setSidePanel(true));
      } else {
        dispatch(setSidePanel(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, dispatch]);

  // Mettre à jour le dernier panel ouvert
  useEffect(() => {
    if (isSidePanelOpen && !isSidePanelProfilOpen) {
      setLastPanelOpened("left");
    } else if (isSidePanelProfilOpen && !isSidePanelOpen) {
      setLastPanelOpened("right");
    }
  }, [isSidePanelOpen, isSidePanelProfilOpen]);

  const getContentSize = () => {
    if (isSidePanelOpen && isSidePanelProfilOpen) {
      return "w-full md:w-3/5";
    } else if (isSidePanelOpen || isSidePanelProfilOpen) {
      return "w-full md:w-4/5";
    } else {
      return "w-full";
    }
  };

  const getContentPosition = () => {
    if (isSidePanelOpen && isSidePanelProfilOpen) {
      return "justify-center";
    } else if (isSidePanelOpen) {
      return "justify-end";
    } else if (isSidePanelProfilOpen) {
      return "justify-start";
    } else {
      return lastPanelOpened === "right" ? "justify-start" : "justify-end";
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div className={`flex ${getContentPosition()} h-full`}>
        {!noSidebar && (
          <SidePanelOptions
            handleShareCollection={handleShareCollection}
            handleSelectCards={handleSelectCards}
          />
        )}
        <div className={`transition-all duration-300 ${getContentSize()}`}>
          <div className="p-6 pt-32 pb-12">{children}</div>
        </div>
        {!noSidebar && <SidePanelProfil />}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
