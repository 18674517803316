import React from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  isVertical?: boolean;
  isLink?: boolean;
  to?: string;
  bottom?: boolean;
  top?: boolean;
  left?: boolean;
  right?: boolean;
  small?: boolean;
  full?: boolean;
  isSubmit?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  isVertical,
  isLink,
  to,
  bottom,
  top,
  left,
  right,
  small,
  full,
  isSubmit,
}) => {
  const borderClasses = `
    ${bottom ? "rounded-b-md border-t-0" : ""}
    ${top ? "rounded-t-md border-b-0" : ""}
    ${left ? "rounded-l-md border-r-0" : ""}
    ${right ? "rounded-r-md border-l-0" : ""}
  `;

  const commonClasses = `group flex justify-center h-auto px-1 md:px-2 lg:px-4 py-2 border-4 ${
    bottom || top || left || right ? borderClasses : "rounded-md"
  } font-chillaxMedium uppercase border-darkBlue bg-darkBlue text-white ${
    isVertical && "[writing-mode:vertical-rl]"
  } ${full && "w-full"} hover:bg-lightBlue hover:text-darkBlue`;

  if (isLink && to) {
    return (
      <Link to={to} className={commonClasses}>
        {children}
      </Link>
    );
  }

  return (
    <button
      type={isSubmit ? "submit" : "button"}
      onClick={onClick}
      className={commonClasses}
    >
      {children}
    </button>
  );
};

export default Button;
