import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserFriendship } from "../types/CardType";

import { fetchCollectionCards } from "../api/fetchCollectionCards";
import { fetchFriendTradingCard } from "../api/fetchFriendTradingCard";
import { fetchFriends } from "../api/fetchFriends";

import useFetchCards from "../hooks/useFetchCards";
import { useTranslation } from "react-i18next";
import Loading from "../parts/Loading";
import { createMyCardsFromText } from "../utils/formatCards";
import Button from "../components/Button";
import Footer from "../parts/Footer";
import { setCollectionCards } from "../store/slices/collectionCardsSlice";
import { setFriends, updateFriend } from "../store/slices/friendsSlice";
import { RootState } from "../store/store";
import cardsData from "../data/cards.json";
import { useCollection } from "../hooks/useCollection";
import { useAuthContext } from "../hooks/useAuthContext";

interface ErrorData {
  code: number;
  message: string;
}

const CollectionSecret = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const [token, setToken] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<ErrorData>();
  const [errorMessageString, setErrorMessageString] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [justUnique, setJustUnique] = useState<boolean>(false);
  const [loadingLabel, setLoadingLabel] = useState<string>("");
  const { updateCollection } = useCollection();
  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );
  const myFriends = useSelector((state: RootState) => state.friends.friends);
  const { allCards } = useFetchCards(cardsData);

  const handleFetchCollectionCards = async () => {
    setLoading(true);

    const updateLoadingLabel = (message: string) => {
      setLoadingLabel(t("loading.loadingCollection") + message);
    };

    setLoadingLabel(t("loading.loadingCollection"));

    const cardsResult = await fetchCollectionCards(
      token,
      setErrorMessage,
      updateLoadingLabel
    );

    setLoadingLabel("");
    setLoading(false);

    if (allCards && cardsResult) {
      const { transformedCards, uniqueCards } = cardsResult;

      const { includedCards } = createMyCardsFromText(
        allCards,
        transformedCards
      );

      const combinedCards = [...includedCards, ...uniqueCards];
      dispatch(setCollectionCards(combinedCards));
      updateCollection(combinedCards, user);
    }
  };

  const handleFetchFriends = async () => {
    setLoading(true);
    setLoadingLabel(t("loading.loadingFriends"));
    let friendsResult: any[] = await fetchFriends(token, setErrorMessage);

    if (friendsResult) {
      const transformedFriendsResult: UserFriendship[] = friendsResult.map(
        (friend) => ({
          userFriend: {
            id: friend.userFriend.id,
            nickName: friend.userFriend.nickName,
            avatarPath: friend.userFriend.avatarPath,
            biography: friend.userFriend.biography,
            background: friend.userFriend.background,
            totalFriends: friend.userFriend.totalFriends,
            friendStatus: friend.userFriend.friendStatus,
            uniqueId: friend.userFriend.uniqueId,
            inMyTradelist: friend.userFriend.inMyTradelist,
          },
          friendshipId: friend.id,
          cards: friend.cards || null,
          lastUpdate: new Date().toISOString(),
        })
      );

      dispatch(setFriends(transformedFriendsResult));

      setLoading(false);
      setLoadingLabel("");
    } else {
      console.error("Failed to fetch friends list");
      setLoading(false);
      setLoadingLabel("");
      return;
    }

    setLoading(false);
    setLoadingLabel("");
  };

  const handleFetchFriendsCards = async () => {
    if (!myFriends.length) {
      setErrorMessageString("You need to fetch your Friends");
      return;
    }

    setLoading(true);
    setLoadingLabel(
      t("loading.loadingFriendsCards", {
        loadedFriends: 0,
        nbFriends: myFriends?.length,
      })
    );

    for (let i = 0; i < myFriends.length; i++) {
      const friend = myFriends[i];

      const friendsCardsResult = await fetchFriendTradingCard(
        token,
        friend.userFriend.id,
        setErrorMessage
      );

      if (friendsCardsResult) {
        const { transformedCards, uniqueCards } = friendsCardsResult;
        const updatedFriend: UserFriendship = {
          ...friend,
          cards: justUnique ? [] : transformedCards,
          uniqueCards: uniqueCards,
        };

        dispatch(updateFriend(updatedFriend));
      }

      setLoadingLabel(
        t("loading.loadingFriendsCards", {
          loadedFriends: i + 1,
          nbFriends: myFriends.length,
        })
      );
    }

    setLoadingLabel("");
    setLoading(false);
  };

  document.title = t("navigation.collection") + " | Altered Card";

  if (loading) {
    return <Loading label={loadingLabel} />;
  }

  return (
    <div className="h-screen flex flex-col justify-between">
      <div className="space-y-8 pt-32 pb-12">
        <div className="px-6 lg:px-20 space-y-2">
          <div className="flex justify-center ">
            <textarea
              rows={4}
              placeholder={t("actions.enterToken")}
              value={token}
              onChange={(e) => setToken(e.target.value)}
              className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
            />
          </div>
          <div className="flex justify-end space-x-2 ">
            <Button onClick={handleFetchCollectionCards}>
              {myCards ? t("actions.refetchcard") : t("actions.fetchcard")}
            </Button>
            <Button onClick={handleFetchFriends}>Charger les amis</Button>
            <Button onClick={handleFetchFriendsCards}>
              Charger les cartes des amis
            </Button>
          </div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={justUnique}
              onChange={() => setJustUnique((unique) => !unique)}
              className="form-checkbox outline-none accent-darkBlue"
            />
            <span className="font-chillaxBold">
              {t("Seulement les uniques")}
            </span>
          </label>
          <div className="justify-center">
            <span className="text-red-600 font-chillaxMedium">
              ⚠️ Avertissement de Sécurité : Utilisation du Token.
              <br />
              Votre token est un élément sensible qui permet d'accéder à vos
              informations personnelles et de réaliser des actions en votre nom.
              Si ce token est volé ou compromis, il pourrait être utilisé par
              des tiers pour accéder à vos données ou effectuer des actions non
              autorisées.
              <br />
              Pour protéger vos informations : Évitez d'utiliser votre token sur
              des appareils ou réseaux non sécurisés. Si vous pensez que votre
              token a été compromis, révoquez-le immédiatement et générez-en un
              nouveau.
            </span>
          </div>
          {errorMessage && (
            <div className="flex justify-center">
              <span className="text-red-600 font-chillaxMedium">
                {errorMessage?.message || errorMessageString}
              </span>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CollectionSecret;
