import React, { useState } from "react";
import { CardType } from "../types/CardType";
import { ExportOption } from "../types/CardType";
import ExportModal from "./ExportModal";

export interface ExportCardsProps {
  cards: CardType[];
  exportOptions: ExportOption[];
  exportToolsOptions: ExportOption[];
  isSealed?: boolean;
}

const ExportCards: React.FC<ExportCardsProps> = ({
  cards,
  exportOptions,
  exportToolsOptions,
  isSealed,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div>
      <button
        onClick={openModal}
        className="flex h-auto px-1 lg:px-4 py-2 border-4 rounded-md font-chillaxMedium uppercase border-darkBlue bg-darkBlue text-white hover:bg-lightBlue hover:text-darkBlue"
      >
        Export
      </button>
      <ExportModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        cards={cards}
        exportOptions={exportOptions}
        exportToolsOptions={exportToolsOptions}
        isSealed={isSealed}
      />
    </div>
  );
};

export default ExportCards;
