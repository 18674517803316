import React from "react";
import CardList from "./CardList";
import { TABS_MY_CARDS } from "../constants/constants";
import { CardType } from "../types/CardType";

interface RenderCardsListProps {
  selectedTab: string;
  myCards?: CardType[];
  missingCards?: CardType[];
  excessCards?: CardType[];
}

const RenderCardsList: React.FC<RenderCardsListProps> = ({
  selectedTab,
  myCards,
  missingCards = [],
  excessCards = [],
}) => {
  if (selectedTab === TABS_MY_CARDS.MY_CARD.key && myCards) {
    return <CardList cards={myCards} showCollectionFilter isExport />;
  }

  if (selectedTab === TABS_MY_CARDS.MISSING.key && missingCards?.length > 0) {
    return <CardList cards={missingCards} showCollectionFilter isExport />;
  }

  if (selectedTab === TABS_MY_CARDS.EXCESS.key && excessCards?.length > 0) {
    return <CardList cards={excessCards} showCollectionFilter isExport />;
  }

  return null;
};

export default RenderCardsList;
