import { CardType } from "../types/CardType";
import { CARD_TYPE, CARD_PRODUCT } from "../constants/constants";

const getRandomUniqueCardByFactionAndRarity = (
  cards: CardType[],
  faction: string,
  types: string[],
  usedCardIds: Set<string>,
  currentLanguage: string
): CardType | null => {
  const filteredCards = cards.filter((card) => {
    return (
      (faction === "" || card.mainFaction === faction) &&
      types?.includes(card.type) &&
      !card.collectorNumberFormatted[currentLanguage]?.includes(
        CARD_PRODUCT.PROMOTION
      ) &&
      !usedCardIds.has(card.reference)
    );
  });
  return filteredCards.length > 0
    ? filteredCards[Math.floor(Math.random() * filteredCards.length)]
    : null;
};

const drawFactionCards = (
  cards: CardType[],
  factions: string[],
  types: string[],
  usedCardIds: Set<string>,
  currentLanguage: string
): CardType[] => {
  const selectedCards: CardType[] = [];

  const firstFaction = Math.random() < 0.5 ? factions[0] : factions[1];
  const secondFaction =
    firstFaction === factions[0] ? factions[1] : factions[0];

  for (let i = 0; i < 2; i++) {
    const card = getRandomUniqueCardByFactionAndRarity(
      cards,
      firstFaction,
      types,
      usedCardIds,
      currentLanguage
    );
    if (card) {
      selectedCards.push(card);
      usedCardIds.add(card.reference);
    }
  }

  const card = getRandomUniqueCardByFactionAndRarity(
    cards,
    secondFaction,
    types,
    usedCardIds,
    currentLanguage
  );
  if (card) {
    selectedCards.push(card);
    usedCardIds.add(card.reference);
  }

  return selectedCards;
};

export const drawBoosterPack = (
  sortedCards: any,
  currentLanguage: string
): CardType[] => {
  const haveUnique = false;
  const usedCardIds = new Set<string>();
  const heroCard = getRandomUniqueCardByFactionAndRarity(
    sortedCards.heroCards,
    "",
    [CARD_TYPE.HERO],
    usedCardIds,
    currentLanguage
  );

  const selectedRareCards = Array.from({ length: 3 }, () =>
    getRandomUniqueCardByFactionAndRarity(
      sortedCards.rareCards,
      "",
      [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
      usedCardIds,
      currentLanguage
    )
  ).filter((card) => card !== null) as CardType[];

  const axiomBravoCards = drawFactionCards(
    sortedCards.commonCards,
    ["AX", "BR"],
    [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
    usedCardIds,
    currentLanguage
  );

  const munaLyraCards = drawFactionCards(
    sortedCards.commonCards,
    ["MU", "LY"],
    [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
    usedCardIds,
    currentLanguage
  );

  const ordisYzmirCards = drawFactionCards(
    sortedCards.commonCards,
    ["OR", "YZ"],
    [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
    usedCardIds,
    currentLanguage
  );

  const commonCards = [
    ...axiomBravoCards,
    ...munaLyraCards,
    ...ordisYzmirCards,
  ];

  if (Math.random() < 0.5 && commonCards.length > 0) {
    const replaceIndex = Math.floor(Math.random() * commonCards.length);
    const commonCard = commonCards[replaceIndex];
    const rareCard = getRandomUniqueCardByFactionAndRarity(
      sortedCards.rareCards,
      commonCard.mainFaction,
      [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
      usedCardIds,
      currentLanguage
    );

    if (rareCard) {
      commonCards[replaceIndex] = rareCard;
    }
  }

  if (haveUnique && Math.random() < 0.15 && selectedRareCards.length > 0) {
    const replaceIndex = Math.floor(Math.random() * selectedRareCards.length);
    const rareCard = selectedRareCards[replaceIndex];
    const uniqueCard = getRandomUniqueCardByFactionAndRarity(
      sortedCards.uniqueCards,
      rareCard.mainFaction,
      [CARD_TYPE.CHARACTER],
      usedCardIds,
      currentLanguage
    );

    if (uniqueCard) {
      selectedRareCards[replaceIndex] = uniqueCard;
    }
  }

  return [heroCard, ...selectedRareCards, ...commonCards].filter(
    (card) => card !== null
  ) as CardType[];
};
