import React from "react";
import CardList from "../parts/CardList";
import useFetchCards from "../hooks/useFetchCards";
import Loading from "../parts/Loading";
import { useTranslation } from "react-i18next";
import Layout from "../parts/Layout";
import cardsData from "../data/cards.json";

const Exploration: React.FC = () => {
  const { t } = useTranslation();
  const { allCards, loading, error } = useFetchCards(cardsData);
  document.title = t("navigation.exploration") + " | Altered Card";

  if (loading) {
    return <Loading label={t("loading.loadingCards")} />;
  }

  if (error) {
    return <p className="text-xl text-red-500">{error}</p>;
  }

  return <Layout>{allCards && <CardList cards={allCards} isExport />}</Layout>;
};

export default Exploration;
