import axios, { AxiosError } from "axios";
import { UserFriendship } from "../types/CardType";

export const fetchFriends = async (
  token: string,
  setErrorMessage: (error: any) => void
): Promise<UserFriendship[]> => {
  try {
    const response = await axios.get(
      "https://api.altered.gg/user_friendships",
      {
        params: {
          itemsPerPage: 1000,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": "fr-fr",
        },
      }
    );
    return response.data["hydra:member"] as UserFriendship[];
  } catch (error) {
    console.error("Error fetching friends:", error);
    setErrorMessage((error as AxiosError).response?.data);
    return [];
  }
};
