import { useState, useEffect } from "react";
import { CardType } from "../types/CardType";
import { CARD_TYPE, RARITY, VERSION } from "../constants/constants";

const dispatchCards = (cardsSorted: CardType[]) => {
  function getRandomUniqueCards(cardsSorted: CardType[], numberOfCards = 100) {
    // Filtrer les cartes pour ne garder que celles avec une rareté UNIQUE
    const uniqueCards = cardsSorted.filter(
      (card) => card.rarity === RARITY.UNIQUE
    );

    // Mélanger les cartes uniques
    const shuffledUniqueCards = uniqueCards.sort(() => 0.5 - Math.random());

    // Sélectionner les 'numberOfCards' premières cartes après mélange
    return shuffledUniqueCards.slice(0, numberOfCards);
  }

  const heroCards = cardsSorted.filter((card) => card.type === CARD_TYPE.HERO);
  const characterCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.CHARACTER
  );
  const spellCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.SPELL
  );
  const permanentCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.PERMANENT
  );
  const tokenCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.TOKEN
  );

  const commonCards = cardsSorted.filter(
    (card) => card.rarity === RARITY.COMMON && card.type !== CARD_TYPE.HERO
  );
  const rareCards = cardsSorted.filter((card) => card.rarity === RARITY.RARE);
  const uniqueCards = getRandomUniqueCards(cardsSorted, 100);

  return {
    heroCards,
    characterCards,
    spellCards,
    permanentCards,
    tokenCards,
    commonCards,
    rareCards,
    uniqueCards,
  };
};

export const filterByVersion = (cards: CardType[], version: string) => {
  if (version === VERSION.KS) {
    return cards.filter((card) => card.reference.includes("COREKS"));
  } else if (version === VERSION.DEFAULT) {
    return cards.filter((card) => card.reference.includes("CORE_"));
  }
  return cards;
};

const useFetchCards = (cardData: any) => {
  const [allCards, setAllCards] = useState<CardType[]>();
  const [sortedCards, setSortedCards] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchCards = async () => {
    try {
      const items = Object.values(cardData) as any[];
      setAllCards(items);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allCards) setSortedCards(dispatchCards(allCards));
  }, [allCards]);

  return { allCards, sortedCards, loading, error };
};

export default useFetchCards;
