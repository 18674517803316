import React from "react";
import { CardType } from "../types/CardType";
import { calculateSummary } from "../utils/cardUtilities";
import Block from "../components/Block";
import { useTranslation } from "react-i18next";
import { MAX_HEROES } from "../constants/constants";

interface SealedSummaryProps {
  cards: CardType[];
  isDeck?: boolean;
}

const flexFaction = (summaryNb: number, faction: string) => {
  return (
    <div className="flex flex-col items-center w-fit relative">
      <span className="absolute text-white font-bold top-0 lg:top-1">
        {summaryNb}
      </span>
      <img
        src={`/assets/factions/${faction}.webp`}
        alt={faction + "banner"}
        className="max-h-28 object-cover"
      />
    </div>
  );
};

const flexRarity = (summaryNb: number, rarity: string) => {
  return (
    <div className="flex flex-col items-center w-fit space-y-2">
      <span className="text-xl">{summaryNb}</span>
      <img
        src={`/assets/rarity/${rarity}.png`}
        alt={rarity + "banner"}
        className="h-3 lg:h-5 object-cover"
      />
    </div>
  );
};

const SealedSummary: React.FC<SealedSummaryProps> = ({ cards, isDeck }) => {
  const summary = calculateSummary(cards);
  const { t } = useTranslation();

  return (
    <div className="grid auto-rows-min space-y-2 w-full">
      <div className="flex space-x-2 items-end">
        <Block number={summary.characters} label="gameElements.characters" />
        <Block number={summary.spells} label="gameElements.spells" />
        <Block number={summary.permanents} label="gameElements.permanents" />
        <Block number={summary.heroes} label="gameElements.heroes" />
      </div>
      <div className="flex space-x-2 items-start">
        <div className="rounded shadow-md flex justify-center items-center p-2 h-full w-fit border-4 border-darkBlue bg-white">
          <div className="flex space-x-1 items-end font-chillaxMedium">
            {flexFaction(summary.ax, "AXIOM")}
            {flexFaction(summary.br, "BRAVOS")}
            {flexFaction(summary.ly, "LYRA")}
            {flexFaction(summary.mu, "MUNA")}
            {flexFaction(summary.or, "ORDIS")}
            {flexFaction(summary.yz, "YZMIR")}
          </div>
        </div>
        <div className="rounded shadow-md flex justify-center items-center p-2 h-fit w-fit border-4 border-darkBlue bg-white">
          <div className="flex space-x-1 items-end font-chillaxMedium">
            {flexRarity(summary.common, "COMMON")}
            {flexRarity(summary.rare, "RARE")}
            {flexRarity(summary.unique, "UNIQUE")}
          </div>
        </div>
      </div>
      {isDeck && summary.heroes > MAX_HEROES && (
        <span className="text-red-600 font-bold">
          {t("errors.maxHeroesErrorSummary", { nbHeroes: summary.heroes })}
        </span>
      )}
    </div>
  );
};

export default SealedSummary;
