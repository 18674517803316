import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import Home from "./pages/Home";
import Sealed from "./pages/Sealed";
import Friends from "./pages/Friends";
import Collection from "./pages/Collection";
import Exploration from "./pages/Exploration";
import CardExchange from "./pages/CardExchange";
import DonationHelper from "./pages/DonationHelper";
import CollectionSecret from "./pages/CollectionSecret";
import SharedCollection from "./pages/SharedCollection";
import UniqueMarket from "./pages/UniqueMarket";

import { SUPPORTED_LANGUAGE } from "./constants/constants";
import NavBarMobile from "./parts/NavBarMobile";
import NavBar from "./parts/NavBar";
import Decks from "./pages/Decks";

const App: React.FC = () => {
  const { i18n } = useTranslation();

  const LanguageHandler: React.FC<{ component: React.FC }> = ({
    component: Component,
  }) => {
    const location = useLocation();
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments[1];

    if (!SUPPORTED_LANGUAGE.includes(lang)) {
      return (
        <Navigate
          to={`/${i18n.language}${location.pathname}${location.search}`}
          replace
        />
      );
    }

    return <Component />;
  };

  const routes = [
    { path: "/:lang", component: Home },
    { path: "/:lang/*", component: Home },
    { path: "/:lang/sealed", component: Sealed },
    { path: "/:lang/friends", component: Friends },
    { path: "/:lang/collection", component: Collection },
    { path: "/:lang/exchange", component: CardExchange },
    { path: "/:lang/coco", component: CollectionSecret },
    { path: "/:lang/shared", component: SharedCollection },
    { path: "/:lang/donation", component: DonationHelper },
    { path: "/:lang/exploration", component: Exploration },
    { path: "/:lang/uniques", component: UniqueMarket },
    { path: "/:lang/decks", component: Decks },
  ];

  return (
    <Router>
      <NavBar />
      <NavBarMobile />
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/${i18n.language}`} replace />}
        />
        {routes.map(({ path, component }) => (
          <Route
            key={path}
            path={path}
            element={<LanguageHandler component={component} />}
          />
        ))}
        <Route
          path="*"
          element={<Navigate to={`/${i18n.language}`} replace />}
        />
      </Routes>
    </Router>
  );
};

export default App;
