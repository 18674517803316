import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiArrowDownCircle } from "react-icons/fi";
import ToolsCard from "../parts/ToolsCard";
import CardSlider from "../components/CardSlider";
import useFetchCards from "../hooks/useFetchCards";
import { TABS_TOOLS, TABS_TOOLS1, TABS_TOOLS2 } from "../constants/constants";
import { getRandomCards } from "../utils/cardUtilities";
import Footer from "../parts/Footer";
import Tabs from "../parts/Tabs";
import cardsData from "../data/cards.json";

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { allCards } = useFetchCards(cardsData);
  const [selectedTab, setSelectedTab] = useState<string>(
    TABS_TOOLS.EXPLORATION.key
  );

  document.title = t("homepage.title") + " | Altered Card";

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="h-full flex flex-col bg-beige">
      <header
        className="relative flex pb-10 flex-col md:flex-row items-center justify-center lg:py-8 pt-32 lg:pt-32 h-screen min-h-screen space-y-6 text-white"
        style={{
          backgroundColor: "#1E2036",
          backgroundImage:
            "radial-gradient(#2f3358 0.5px, transparent 0.5px), radial-gradient(#2f3358 0.5px, #1E2036 0.5px)",
          backgroundSize: "26px 26px",
          backgroundPosition: "0 0, 13px 13px",
        }}
      >
        <div className="flex flex-col w-full lg:w-3/4 text-center md:text-left px-4 lg:px-16">
          <h1 className="text-3xl md:text-3xl lg:text-4xl font-chillaxBold mb-4">
            {t("homepage.title")}
          </h1>
          <p className="text-base md:text-lg">{t("homepage.intro")}</p>
        </div>
        <div className="w-full lg:w-2/3 h-full md:h-full">
          {allCards && <CardSlider cards={getRandomCards(allCards, 10)} />}
        </div>
        <div
          className="absolute text-3xl bottom-4 animate-bounce-up-down cursor-pointer"
          onClick={handleScrollToBottom}
        >
          <div className="flex flex-col justify-center items-center space-y-1">
            <span className="text-sm font-chillaxMedium text-lightBlue">
              {t("actions.scrollBottom")}
            </span>
            <FiArrowDownCircle />
          </div>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12 text-darkBlue">
        <section className="space-y-16">
          <h2 className="text-2xl lg:text-3xl font-chillaxBold text-center">
            {t("homepage.tools")}
          </h2>
          <div className="space-y-6">
            <div className="hidden md:block">
              <Tabs
                tabs={TABS_TOOLS}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                small
              />
            </div>
            <div className="md:hidden space-y-2">
              <Tabs
                tabs={TABS_TOOLS1}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                small
              />
              <Tabs
                tabs={TABS_TOOLS2}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                small
              />
            </div>
            {selectedTab === TABS_TOOLS.EXPLORATION.key && (
              <ToolsCard
                title={t("homepage.exploreCards.title")}
                desc={t("homepage.exploreCards.description")}
                label={t("homepage.exploreCards.link")}
                mediaSrc={process.env.PUBLIC_URL + "/assets/videos/cards.webm"}
                mediaType="video"
                page="cards"
              />
            )}
            {selectedTab === TABS_TOOLS.COLLECTION.key && (
              <ToolsCard
                title={t("homepage.collectionCards.title")}
                desc={t("homepage.collectionCards.description")}
                label={t("homepage.collectionCards.link")}
                mediaSrc={
                  process.env.PUBLIC_URL + "/assets/videos/collection.webm"
                }
                mediaType="video"
                page="collection"
              />
            )}
            {selectedTab === TABS_TOOLS.EXCHANGE.key && (
              <ToolsCard
                title={t("homepage.cardExchange.title")}
                desc={t("homepage.cardExchange.description")}
                label={t("homepage.cardExchange.link")}
                mediaSrc={
                  process.env.PUBLIC_URL + "/assets/videos/exchange.webm"
                }
                mediaType="video"
                page="exchange"
              />
            )}
            {selectedTab === TABS_TOOLS.DONATION.key && (
              <ToolsCard
                title={t("homepage.donationHelper.title")}
                desc={t("homepage.donationHelper.description")}
                label={t("homepage.donationHelper.link")}
                mediaSrc={
                  process.env.PUBLIC_URL + "/assets/videos/donation.webm"
                }
                mediaType="video"
                page="donation"
              />
            )}
            {selectedTab === TABS_TOOLS.SEALED.key && (
              <ToolsCard
                title={t("homepage.sealedSimulation.title")}
                desc={t("homepage.sealedSimulation.description")}
                label={t("homepage.sealedSimulation.link")}
                mediaSrc={process.env.PUBLIC_URL + "/assets/videos/sealed.webm"}
                mediaType="video"
                page="sealed"
              />
            )}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
