import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidePanelProfilState {
  isSidePanelProfilOpen: boolean;
}

const initialState: SidePanelProfilState = {
  isSidePanelProfilOpen: false,
};

const sidePanelProfilSlice = createSlice({
  name: "sidePanelProfil",
  initialState,
  reducers: {
    toggleSidePanel(state) {
      state.isSidePanelProfilOpen = !state.isSidePanelProfilOpen;
    },
    setSidePanel(state, action: PayloadAction<boolean>) {
      state.isSidePanelProfilOpen = action.payload;
    },
  },
});

export const { toggleSidePanel, setSidePanel } = sidePanelProfilSlice.actions;

export default sidePanelProfilSlice.reducer;
