import React from "react";

interface SeparatorProps {
  small?: boolean;
}

const SmallSeparator: React.FC<SeparatorProps> = ({ small }) => {
  return (
    <div className={`w-2 h-full ${small ? "pt-5" : "pt-8"}`}>
      <div className={`border-b-4 border-darkBlue `} />
    </div>
  );
};

export default SmallSeparator;
