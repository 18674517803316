export const CARD_TYPE: { [key: string]: string } = {
  HERO: "HERO",
  CHARACTER: "CHARACTER",
  SPELL: "SPELL",
  PERMANENT: "PERMANENT",
  TOKEN: "TOKEN",
  FOILER: "FOILER",
  TOKEN_MANA: "TOKEN_MANA",
};

export const RARITY: { [key: string]: string } = {
  COMMON: "COMMON",
  RARE: "RARE",
  UNIQUE: "UNIQUE",
};

export const VERSION: { [key: string]: string } = {
  ALL: "all",
  KS: "ks",
  DEFAULT: "default",
};

export const FACTIONS: { [key: string]: string } = {
  YZ: "YZ",
  AX: "AX",
  BR: "BR",
  MU: "MU",
  LY: "LY",
  OR: "OR",
};

export const CARD_PRODUCT: { [key: string]: string } = {
  BOOSTER: "B",
  PROMOTION: "BTG_p",
};

export const FRIEND_SORT_MODE: { [key: string]: string } = {
  BY_TRADE_CARDS: "BY_TRADE_CARDS",
  BY_LAST_UPDATE: "BY_LAST_UPDATE",
  BY_TOTAL_TRADES: "BY_TOTAL_TRADES",
};

export const PRIMARY_SORT_MODE: { [key: string]: string } = {
  BY_NOTHING: "BY_NOTHING",
  BY_TYPE: "BY_TYPE",
  BY_FACTION: "BY_FACTION",
};

export const SECONDARY_SORT_MODE: { [key: string]: string } = {
  BY_NOTHING: "BY_NOTHING",
  BY_LATEST_ADDITION: "BY_LATEST_ADDITION",
  BY_MAIN_COST: "BY_MAIN_COST",
  BY_NAME: "BY_NAME",
  BY_NUMBER: "BY_NUMBER",
};

export const SORT_DIRECTION: { [key: string]: string } = {
  ASCENDING: "ASCENDING",
  DESCENDING: "DESCENDING",
};

export const TABS = {
  DRAW: { key: "DRAW", label: "navigation.drawTab" },
  DECK: { key: "DECK", label: "navigation.deckTab" },
};

export const TABS_DECKS = {
  DECK: { key: "DECK", label: "navigation.deckTab" },
  MISSING: { key: "DRAW", label: "navigation.missing" },
};

export const TABS_MY_CARDS = {
  MY_CARD: { key: "MY_CARD", label: "navigation.owned" },
  MISSING: { key: "MISSING", label: "navigation.missing" },
  EXCESS: { key: "EXCESS", label: "navigation.surplus" },
};

export const TABS_SHARED_CARDS = {
  COLLECTION: { key: "COLLECTION", label: "navigation.collection" },
  MISSING: { key: "MISSING", label: "navigation.missing" },
  EXCESS: { key: "EXCESS", label: "navigation.surplus" },
};

export const TABS_EXCHANGE = {
  PLAYER1: { key: "PLAYER1", label: "navigation.player1" },
  PLAYER2: { key: "PLAYER2", label: "navigation.player2" },
};

export const TABS_TOOLS = {
  EXPLORATION: { key: "EXPLORATION", label: "navigation.exploration" },
  COLLECTION: { key: "COLLECTION", label: "navigation.collection" },
  EXCHANGE: { key: "EXCHANGE", label: "navigation.simExchange" },
  DONATION: { key: "DONATION", label: "navigation.donation" },
  SEALED: { key: "SEALED", label: "navigation.sealedGenerator" },
};

export const TABS_TOOLS1 = {
  CARDS: { key: "EXPLORATION", label: "navigation.exploration" },
  COLLECTION: { key: "COLLECTION", label: "navigation.collection" },
};

export const TABS_TOOLS2 = {
  EXCHANGE: { key: "EXCHANGE", label: "navigation.simExchange" },
  DONATION: { key: "DONATION", label: "navigation.donation" },
  SEALED: { key: "SEALED", label: "navigation.sealedGenerator" },
};

export const TABS_MARKET = {
  MARKET: { key: "MARKET", label: "navigation.market" },
  FAVORITE: { key: "FAVORITE", label: "navigation.favorite" },
};

export const NUMBER_FILTERS: { [key: string]: string } = {
  EQUALS: "equals",
  GREATER: "greater",
  LESS: "less",
};

export const LANGUAGES: { [key: string]: string } = {
  FRENCH: "fr",
  ENGLISH: "en",
  SPAIN: "es",
  ITALY: "it",
  GERMANY: "de",
};

export const SUPPORTED_LANGUAGE: string[] = [
  LANGUAGES.FRENCH,
  LANGUAGES.ENGLISH,
  LANGUAGES.SPAIN,
  LANGUAGES.ITALY,
  LANGUAGES.GERMANY,
];

export const EXPORT_CARD_FORMAT: { [key: string]: string } = {
  ID: "id",
  NAME: "name",
};

export const EXPORT_FORMAT: { [key: string]: string } = {
  BASE64: "BASE64",
  NORMAL: "NORMAL",
  CSV: "CSV",
};

export const MAX_HEROES: number = 1;
export const MIN_CARDS: number = 1;
export const MAX_FACTIONS: number = 3;
