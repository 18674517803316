import { useTranslation } from "react-i18next";

interface BlockProps {
  number: number;
  label: string;
}

const Block = ({ number, label }: BlockProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center h-full w-full p-2 lg:px-4 rounded shadow-md font-chillaxMedium bg-darkBlue text-white">
      <h2 className="lg:text-4xl text-3xl font-bold">{number}</h2>
      <p className="font-light font lg:text-xs text-xs lowercase">{t(label)}</p>
    </div>
  );
};

export default Block;
