import { useState, useEffect } from "react";
import { CardType } from "../types/CardType";

interface UseCardInputProps {
  allCards: CardType[] | undefined;
}

export const useCardInput = ({ allCards = [] }: UseCardInputProps) => {
  const [cardInput, setCardInput] = useState("");
  const [selectedCards, setSelectedCards] = useState<CardType[]>([]);

  const handleCardInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCardInput(e.target.value);
  };

  useEffect(() => {
    const cardEntries = cardInput.split("\n");
    const updatedSelectedCards: CardType[] = [];

    cardEntries.forEach((entry) => {
      const [count, id] = entry.split(" ");
      const card = allCards.find((card) => card.reference === id);
      if (card && !isNaN(parseInt(count, 10))) {
        updatedSelectedCards.push({ ...card, nbSelected: parseInt(count, 10) });
      }
    });

    setSelectedCards(updatedSelectedCards);
  }, [cardInput, allCards]);

  return {
    cardInput,
    selectedCards,
    handleCardInputChange,
    setSelectedCards,
    setCardInput,
  };
};
