import {
  handleAlteredTgcBuilder,
  handleCopyToClipboard,
  handleExportIDs,
  handleExportToAlteredDB,
  handleExportToExaltsTable,
  handleSimulatorFirstHand,
  handleExportToExchangeTool,
} from "../utils/exportUtilities";

import { ExportOption } from "../types/CardType";

export const exportOptions: ExportOption[] = [
  { handler: handleCopyToClipboard, translationKey: "export.copyClipboard" },
  { handler: handleExportIDs, translationKey: "export.exportTXT" },
];

export const exportToolsCardsOptions: ExportOption[] = [
  {
    handler: handleExportToAlteredDB,
    translationKey: "export.exportAlteredDB",
  },
  {
    handler: handleAlteredTgcBuilder,
    translationKey: "export.exportTGCBuilder",
  },
];

export const exportToolsCollectionOptions: ExportOption[] = [
  {
    handler: handleExportToAlteredDB,
    translationKey: "export.exportAlteredDB",
  },
  {
    handler: handleExportToExaltsTable,
    translationKey: "export.exportExaltsTable",
  },
  {
    handler: handleAlteredTgcBuilder,
    translationKey: "export.exportTGCBuilder",
  },
  {
    handler: handleSimulatorFirstHand,
    translationKey: "export.exportFirstHand",
  },
];

export const exportToolsSealedOptions: ExportOption[] = [
  {
    handler: handleExportToAlteredDB,
    translationKey: "export.exportAlteredDB",
  },
  {
    handler: handleExportToExaltsTable,
    translationKey: "export.exportExaltsTable",
  },
  {
    handler: handleAlteredTgcBuilder,
    translationKey: "export.exportTGCBuilder",
  },
  {
    handler: handleSimulatorFirstHand,
    translationKey: "export.exportFirstHand",
  },
];

export const exportMyCardsOptions: ExportOption[] = [
  { handler: handleCopyToClipboard, translationKey: "export.copyClipboard" },
  { handler: handleExportIDs, translationKey: "export.exportTXT" },
  {
    handler: handleExportToExchangeTool,
    translationKey: "export.exportExchangeTool",
  },
  {
    handler: handleExportToAlteredDB,
    translationKey: "export.exportAlteredDB",
  },
];
