import React from "react";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Separator from "../components/Separator";

interface LegalModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LegalModal: React.FC<LegalModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-75 z-[1000]"
    >
      <div className="bg-beige p-4 rounded shadow-lg lg:w-1/2 h-4/6 space-y-4 overflow-scroll relative border-4 border-darkBlue">
        <button
          onClick={onClose}
          className="absolute text-2xl font-bold top-3 right-3 text-darkBlue hover:text-gray-800 cursor-pointer"
        >
          ✕
        </button>
        <h2 className="text-2xl font-bold mb-4 font-chillaxMedium">
          {t("legal.title")}
        </h2>
        <div className="space-y-4 text-darkBlue font-chillaxRegular">
          <p
            dangerouslySetInnerHTML={{
              __html: t("legal.functionalityDescription"),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("legal.developer"),
            }}
          />
          <h3 className="text-xl font-semibold">
            {t("legal.dataStorageTitle")}
          </h3>
          <p
            dangerouslySetInnerHTML={{
              __html: t("legal.dataStorageDescription"),
            }}
          />
          <h3 className="text-xl font-semibold">{t("legal.hostingTitle")}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: t("legal.hostingDescription"),
            }}
          />
          <h3 className="text-xl font-semibold">{t("legal.thanksTitle")}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: t("legal.thanksDescription"),
            }}
          />
          <h3 className="text-xl font-semibold">{t("legal.copyrightTitle")}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: t("legal.copyrightDescription"),
            }}
          />
          <h3 className="text-xl font-semibold">{t("legal.contactTitle")}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: t("legal.contactDescription"),
            }}
          />
          <Separator />
          <p>{t("legal.info")}</p>
        </div>
      </div>
    </Modal>
  );
};

export default LegalModal;
