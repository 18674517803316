import React from "react";
import Button from "../components/Button";
import SmallSeparator from "../components/SmallSeparator";
import ExportCards from "./ExportCards";
import { exportOptions, exportToolsCardsOptions } from "../data/exportData";
import { CardType } from "../types/CardType";
import { useTranslation } from "react-i18next";

interface ButtonGroupProps {
  isExport: boolean;
  isDonation: boolean;
  isClose: boolean;
  goTo?: string;
  handleOpenDonationPages: () => void;
  handleClose?: () => void;
  handleNavigateTo?: () => void;
  filteredCards: CardType[];
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  isExport,
  isDonation,
  isClose,
  goTo,
  handleOpenDonationPages,
  handleClose,
  handleNavigateTo,
  filteredCards,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-end">
      {isExport && <SmallSeparator />}
      {isExport && (
        <ExportCards
          cards={filteredCards}
          exportOptions={exportOptions}
          exportToolsOptions={exportToolsCardsOptions}
        />
      )}
      {isDonation && <SmallSeparator />}
      {isDonation && (
        <Button onClick={handleOpenDonationPages}>
          {t("navigation.donation")}
        </Button>
      )}
      {isClose && <SmallSeparator />}
      {isClose && <Button onClick={handleClose}>{t("actions.close")}</Button>}
      {goTo && <SmallSeparator />}
      {goTo && (
        <Button onClick={handleNavigateTo}>{t("actions.goToProfil")}</Button>
      )}
    </div>
  );
};

export default ButtonGroup;
