import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store/store";
import useFetchCards from "../hooks/useFetchCards";
import { TABS_MY_CARDS } from "../constants/constants";
import { getMissingCards, getExcessCards } from "../utils/cardUtilities";
import { useTranslation, Trans } from "react-i18next";
import { createMyCardsFromText } from "../utils/formatCards";
import ButtonWithConfirmation from "../components/ButtonWithConfirmation";
import RenderCardsList from "../parts/RenderCardsList";
import CardSelectionModal from "../parts/CardSelectionModal";
import ShareCollectionModal from "../parts/ShareCollectionModal";
import { linkTo } from "../utils/linkTo";
import { useCardInput } from "../hooks/useCardInput";
import { CardStatsInfo } from "../types/CardType";
import Tabs from "../parts/Tabs";
import { setCollectionCards } from "../store/slices/collectionCardsSlice";
import Layout from "../parts/Layout";
import cardsData from "../data/cards.json";
import { useCollection } from "../hooks/useCollection";
import { useAuthContext } from "../hooks/useAuthContext";
import { toast } from "react-toastify";
import Button from "../components/Button";
import { getNavigateUrl } from "../utils/getNavigateUrl";

const Collection = () => {
  const { i18n, t } = useTranslation();
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const { allCards } = useFetchCards(cardsData);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string>(
    TABS_MY_CARDS.MY_CARD.key
  );

  const { updateCollection, getCollection, error } = useCollection();

  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );

  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );

  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );

  const myCardsEmpty = !(myCards?.length > 0);

  useEffect(() => {
    const fetchCollection = async () => {
      const result = await getCollection(user);
      result && dispatch(setCollectionCards(result));
    };
    if (user?.token) fetchCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    toast.warning(error);
  }, [error]);

  const {
    cardInput,
    selectedCards,
    handleCardInputChange,
    setSelectedCards,
    setCardInput,
  } = useCardInput({
    allCards,
  });

  const missingCards =
    myCards &&
    allCards &&
    getMissingCards(myCards, allCards, rareQuantity, commonQuantity);
  const excessCards =
    myCards && getExcessCards(myCards, rareQuantity, commonQuantity);

  const handleCardInput = () => {
    const cardEntries = cardInput.split("\n");
    const cardData: CardStatsInfo[] = cardEntries.map((entry) => {
      const [count, reference] = entry.split(" ");
      return {
        reference,
        inMyCollection: parseInt(count, 10) || 0,
      } as CardStatsInfo;
    });

    if (allCards) {
      const { includedCards } = createMyCardsFromText(allCards, cardData);
      if (includedCards.length > 0) {
        dispatch(setCollectionCards(includedCards));
        updateCollection(includedCards, user);
      } else {
        setErrorMessage(t("errors.invalidCardList"));
      }
    }
  };

  const handleCloseModal = () => {
    const selectedCardIds = selectedCards
      .map((card) => `${card.nbSelected || 1} ${card.reference}`)
      .join("\n");
    setCardInput(selectedCardIds);
    setModalIsOpen(false);
  };

  const handleGoToCoco = () => {
    navigate(getNavigateUrl(i18n.language, "coco"));
  };

  document.title = t("navigation.collection") + " | Altered Card";

  return (
    <Layout
      handleShareCollection={() => setShareModalIsOpen(true)}
      handleSelectCards={() => setModalIsOpen(true)}
    >
      <div className="space-y-10">
        <div className="flex justify-center items-center w-full">
          <div className="w-full items-start px-6 lg:px-20">
            <div className="flex flex-row space-x-2 justify-center items-center">
              {user?.betaTester && (
                <>
                  <Button onClick={handleGoToCoco}>
                    Charger votre collection
                  </Button>
                  <span className="font-chillaxBold text-darkBlue">
                    {t("connecter.or")}
                  </span>
                </>
              )}

              <div className="flex justify-center w-full">
                <textarea
                  rows={4}
                  placeholder={t("actions.enterYourCards")}
                  value={cardInput}
                  onChange={handleCardInputChange}
                  className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                />
                <ButtonWithConfirmation
                  onConfirm={handleCardInput}
                  onCancel={() => {}}
                  confirmText={t("actions.confirm")}
                  cancelText={t("actions.cancel")}
                >
                  {myCards && !myCardsEmpty
                    ? t("actions.refetchcard")
                    : t("actions.fetchcard")}
                </ButtonWithConfirmation>
              </div>
            </div>
            {/* Discord Link */}
            <div className="flex justify-center items-center font-chillaxMedium bottom-0 px-2 md:px-8 w-full text-center">
              <span>
                <Trans
                  i18nKey="navigation.tutoDiscord"
                  components={{
                    LinkTo: linkTo({
                      dest: "https://discord.gg/8yvbc3bk8h",
                    }),
                    Bold: <strong />,
                  }}
                />
              </span>
            </div>
            {/* Error Message */}
            {errorMessage && (
              <div className="flex justify-center">
                <span className="text-red-600">{errorMessage}</span>
              </div>
            )}
          </div>
        </div>

        {/* Cards List Section */}
        {!myCardsEmpty && (
          <div className="space-y-2 lg:space-y-8">
            {myCards && (
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tabs={TABS_MY_CARDS}
              />
            )}
            <RenderCardsList
              selectedTab={selectedTab}
              myCards={myCards}
              missingCards={missingCards}
              excessCards={excessCards}
            />
          </div>
        )}

        {/* Modals */}
        <CardSelectionModal
          isOpen={modalIsOpen}
          onClose={handleCloseModal}
          cards={allCards || []}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          quantity
        />
        {myCards && allCards && (
          <ShareCollectionModal
            isOpen={shareModalIsOpen}
            onClose={() => setShareModalIsOpen(false)}
            myCards={myCards}
            allCards={allCards}
          />
        )}
      </div>
    </Layout>
  );
};

export default Collection;
