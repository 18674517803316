import React from "react";

interface SeparatorProps {
  desktop?: boolean;
}

const SeparatorVertical: React.FC<SeparatorProps> = ({ desktop }) => {
  return (
    <div
      className={`border-l-4 border-darkBlue rounded w-1 h-full ${
        desktop ? "hidden lg:block" : ""
      }`}
    />
  );
};

export default SeparatorVertical;
