import React, { useState, useEffect } from "react";
import { CardType } from "../types/CardType";
import { useTranslation, Trans } from "react-i18next";
import { LANGUAGES } from "../constants/constants";
import Modal from "@mui/material/Modal";
import ShinyCard from "@mountainpass/react-pokemon-cards";
import { isMobile } from "react-device-detect";
import Button from "./Button";

interface CardModalProps {
  card: CardType | null;
  isOpen: boolean;
  onRequestClose: () => void;
}

const foilOptions = [
  "rare holo v",
  "rare holo vmax",
  "rare holo vstar",
  "rare rainbow",
  "rare rainbow alt",
  "rare secret",
  "rare ultra",
];

const defaultFoil = "custom";

const CardModal: React.FC<CardModalProps> = ({
  card,
  isOpen,
  onRequestClose,
}) => {
  const { t, i18n } = useTranslation();
  const [foil, setFoil] = useState<any>(defaultFoil);
  const [eloData, setEloData] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);

  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  useEffect(() => {
    if (isOpen) {
      setFoil(defaultFoil);
      setEloData(null);
      if (card?.rarity === "UNIQUE") {
        fetchEloData(card.reference);
      }
    }
  }, [isOpen, card]);

  const fetchEloData = async (cardId: string) => {
    try {
      const response = await fetch(`https://uniquesranking.com/json/${cardId}`);
      const data = await response.json();
      if (data.error > 0) {
        setError(true);
      } else {
        setEloData(data);
      }
    } catch (err) {
      console.error("Failed to fetch elo data:", err);
      setError(true);
    }
  };

  const handleDoubleClick = () => {
    const randomFoil =
      foilOptions[Math.floor(Math.random() * foilOptions.length)];
    setFoil(randomFoil);
  };

  if (!card) return null;

  return (
    <Modal
      open={isOpen}
      onClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center py-40 lg:py-32 px-4 bg-black bg-opacity-75"
    >
      <div className="outline-none space-y-6">
        {card.rarity === "UNIQUE" && (
          <div className="mt-4 text-white text-center font-chillaxMedium">
            {error ? (
              <p>{t("cardModal.privateEloMessage")}</p>
            ) : eloData ? (
              <div>
                <p>
                  {t("cardModal.elo")}:{" "}
                  <strong>{eloData.elo.toFixed(2)}</strong>
                </p>
                <p>
                  {t("cardModal.averageElo")}:{" "}
                  <strong>{eloData.avg_elo.toFixed(2)}</strong>
                </p>
                <p>
                  {t("cardModal.numberOfDuels")}:{" "}
                  <strong>{eloData.nb_duel}</strong>
                </p>
              </div>
            ) : (
              <p>{t("cardModal.loadingElo")}</p>
            )}
          </div>
        )}
        <div
          onDoubleClick={handleDoubleClick}
          className="w-full flex-1 select-none border-0 outline-0"
        >
          {isMobile ? (
            <img
              src={card.imagePath[currentLanguage]}
              alt={card.name[currentLanguage]}
              className="w-full h-auto rounded custom-shadow"
            />
          ) : (
            <ShinyCard
              rarity={foil}
              supertype="pokémon"
              subtype="basic"
              dataGallery="true"
            >
              <img
                src={card.imagePath[currentLanguage]}
                alt={`${card.name[currentLanguage]}-holo`}
                className="rounded max-h-[500px]"
              />
            </ShinyCard>
          )}
        </div>
        {card.owner && (
          <div className="flex flex-col text-white text-center font-chillaxMedium space-y-2">
            {card.lastUpdate && (
              <p>
                <Trans
                  i18nKey="friendship.lastUpdate"
                  values={{
                    date: new Date(card.lastUpdate).toLocaleString(undefined, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    }),
                  }}
                  components={{
                    Bold: <strong />,
                  }}
                />
              </p>
            )}
            <Button
              onClick={() => {
                window.open(`https://www.altered.gg/profile/${card.owner}`);
              }}
            >
              {t("actions.goToProfil") + " - " + card.owner}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CardModal;
