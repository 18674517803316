import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import collectionCardsReducer from "./slices/collectionCardsSlice";
import friendsReducer from "./slices/friendsSlice";
import sidePanelReducer from "./slices/sidePanelSlice";
import SidePanelProfilReducer from "./slices/sidePanelProfilSlice";

// Configuration de persistence
const persistConfig = {
  key: "root",
  storage,
};

// Combiner les reducers
const rootReducer = combineReducers({
  collectionCards: collectionCardsReducer,
  sidePanel: sidePanelReducer,
  sidePanelProfil: SidePanelProfilReducer,
  friends: friendsReducer,
});

// Créer un reducer persistant
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configurer le store avec le reducer persistant
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
        ignoredPaths: ["register", "rehydrate"],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
