import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidePanelState {
  isSidePanelOpen: boolean;
  isColumnSelectionEnabled: boolean;
  isSidePanelCloseDefault: boolean;
  columns: number | undefined;
  isFoilEnabled: boolean;
  rareQuantity: number;
  commonQuantity: number;
  cardsPerPage: number;
}

const initialState: SidePanelState = {
  isSidePanelOpen: false,
  isColumnSelectionEnabled: false,
  isSidePanelCloseDefault: false,
  columns: undefined,
  isFoilEnabled: false,
  rareQuantity: 3,
  commonQuantity: 3,
  cardsPerPage: 50,
};

const sidePanelSlice = createSlice({
  name: "sidePanel",
  initialState,
  reducers: {
    toggleSidePanel(state) {
      state.isSidePanelOpen = !state.isSidePanelOpen;
    },
    setSidePanel(state, action: PayloadAction<boolean>) {
      state.isSidePanelOpen = action.payload;
    },
    setColumnSelectionEnabled(state, action: PayloadAction<boolean>) {
      state.isColumnSelectionEnabled = action.payload;
      if (!action.payload) {
        state.columns = undefined;
      }
    },
    toggleFoilSidePanelCloseDefault(state) {
      state.isSidePanelCloseDefault = !state.isSidePanelCloseDefault;
    },
    setColumns(state, action: PayloadAction<number | undefined>) {
      state.columns = action.payload;
    },
    toggleFoil(state) {
      state.isFoilEnabled = !state.isFoilEnabled;
    },
    setRareQuantity(state, action: PayloadAction<number>) {
      state.rareQuantity = action.payload;
    },
    setCommonQuantity(state, action: PayloadAction<number>) {
      state.commonQuantity = action.payload;
    },
    setCardsPerPage(state, action: PayloadAction<number>) {
      state.cardsPerPage = action.payload;
    },
  },
});

export const {
  toggleSidePanel,
  setSidePanel,
  setColumnSelectionEnabled,
  toggleFoilSidePanelCloseDefault,
  setColumns,
  toggleFoil,
  setRareQuantity,
  setCommonQuantity,
  setCardsPerPage,
} = sidePanelSlice.actions;

export default sidePanelSlice.reducer;
