import { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { toggleSidePanel } from "../store/slices/sidePanelProfilSlice";
import { RootState } from "../store/store";
import Separator from "../components/Separator";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

const SidePanelProfil = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const isSidePanelProfilOpen = useSelector(
    (state: RootState) => state.sidePanelProfil.isSidePanelProfilOpen
  );

  const [isContentVisible, setIsContentVisible] = useState(false);
  const [modalLoginIsOpen, setModalLoginIsOpen] = useState(false);
  const [modalRegisterIsOpen, setModalRegisterIsOpen] = useState(false);

  const openModalLogin = () => setModalLoginIsOpen(true);
  const closeModalLogin = () => setModalLoginIsOpen(false);

  const openModalRegister = () => setModalRegisterIsOpen(true);
  const closeModalRegister = () => setModalRegisterIsOpen(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isSidePanelProfilOpen) {
      timeout = setTimeout(() => setIsContentVisible(true), 150);
    } else {
      setIsContentVisible(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isSidePanelProfilOpen]);

  return (
    <div
      className={`fixed right-0 transition-all duration-300 z-40 pt-16 pb-8 ${
        isSidePanelProfilOpen ? "w-full md:w-1/5" : "w-0"
      } shadow-xl overflow-hidden border-l-4 border-darkBlue bg-beige`}
      style={{ height: "100vh" }}
    >
      {!isSidePanelProfilOpen ? (
        <button
          onClick={() => dispatch(toggleSidePanel())}
          className="hidden md:block group fixed top-1/2 h-1/4 -right-4 transform -translate-y-1/2 py-3 p-1 z-50 bg-darkBlue text-white text-xl rounded-l-md transition-all duration-300 hover:right-0"
        >
          <HiOutlineDotsVertical className="group-hover:scale-110 transition-transform duration-300" />
        </button>
      ) : (
        isContentVisible && (
          <div className="relative flex flex-col space-y-10 md:justify-between h-full pt-10 pb-20 md:pl-10 p-4 md:pt-4 md:pb-4 font-chillaxMedium text-xs md:text-base">
            {user && (
              <div>
                <h3 className="text-xl font-chillaxBold capitalize">
                  {user?.pseudo}
                </h3>
                <h3>{user?.email}</h3>
              </div>
            )}

            <div className="flex flex-col justify-center items-center space-y-2">
              {user ? (
                <Button onClick={logout} full>
                  {t("actions.logout")}
                </Button>
              ) : (
                <>
                  <Button onClick={openModalLogin} full>
                    {t("actions.login")}
                  </Button>
                  <Button onClick={openModalRegister} full>
                    {t("actions.register")}
                  </Button>
                </>
              )}
              <div className="md:hidden w-full space-y-2">
                <Separator />
                <Button onClick={() => dispatch(toggleSidePanel())} full>
                  {t("actions.close")}
                </Button>
              </div>
            </div>
            <button
              onClick={() => dispatch(toggleSidePanel())}
              className="hidden md:block absolute top-1/2 transform -translate-y-1/2 left-0 py-3 p-1 rounded-r-md bg-darkBlue text-white text-xl"
            >
              <CgClose />
            </button>
          </div>
        )
      )}
      <LoginModal isOpen={modalLoginIsOpen} closeModal={closeModalLogin} />
      <RegisterModal
        isOpen={modalRegisterIsOpen}
        closeModal={closeModalRegister}
      />
    </div>
  );
};

export default SidePanelProfil;
