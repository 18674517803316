import { CardType } from "../types/CardType";
import { toast } from "react-toastify";
import { downloadFile } from "./fileUtils";
import { EXPORT_FORMAT, EXPORT_CARD_FORMAT } from "../constants/constants";
import i18n from "i18next";

// Type de la fonction d'export avec des paramètres sous forme d'objet
type ExportHandlerParams = {
  cards: CardType[];
  needFilling?: boolean;
  label?: string;
  cardFormat?: string;
  exportFormat?: string;
};

export type ExportHandler = (params: ExportHandlerParams) => void;

const fillerCardReference = "ALT_CORE_B_NE_1_C";

// Génération du texte d'exportation du deck
export const generateExportDeckText = (
  cards: CardType[],
  lineSeparator: string = "\n",
  needFilling?: boolean,
  cardFormat:
    | typeof EXPORT_CARD_FORMAT.ID
    | typeof EXPORT_CARD_FORMAT.NAME = EXPORT_CARD_FORMAT.ID
): string => {
  let exportDeckText = cards
    .map((card) => {
      const quantity = card.inMyCollection
        ? card.inMyCollection.toString()
        : "1";
      const cardIdentifier =
        cardFormat === EXPORT_CARD_FORMAT.ID
          ? card.reference
          : card.name[i18n.language];
      return `${quantity} ${cardIdentifier}`;
    })
    .join(lineSeparator);

  if (needFilling) {
    for (let i = cards.length; i < 30; i++) {
      exportDeckText += `${lineSeparator}1 ${fillerCardReference}`;
    }
  }

  return exportDeckText;
};

const formatExportText = (text: string, exportFormat: string): string => {
  if (exportFormat === EXPORT_FORMAT.BASE64) {
    return btoa(text); // Convertir en Base64
  } else if (exportFormat === EXPORT_FORMAT.CSV) {
    return convertToCSV(text); // Convertir en CSV
  }
  return text; // Retourner le texte normal si aucun format spécifique
};

const convertToCSV = (text: string): string => {
  return text
    .split("\n")
    .map((line) => line.split(" ").join(","))
    .join("\n");
};

// Exportation du deck sous forme de fichier texte
export const handleExportIDs: ExportHandler = ({
  cards,
  needFilling,
  cardFormat = EXPORT_CARD_FORMAT.ID,
  exportFormat = EXPORT_FORMAT.NORMAL,
}) => {
  let exportDeckText = generateExportDeckText(
    cards,
    "\n",
    needFilling,
    cardFormat
  );

  exportDeckText = formatExportText(exportDeckText, exportFormat);

  downloadFile(
    cardFormat === EXPORT_CARD_FORMAT.ID ? "card_ids.txt" : "card_names.txt",
    exportDeckText
  );
};

// Copier le texte du deck dans le presse-papiers
export const handleCopyToClipboard: ExportHandler = ({
  cards,
  needFilling,
  label,
  cardFormat = EXPORT_CARD_FORMAT.ID,
  exportFormat = EXPORT_FORMAT.NORMAL,
}) => {
  let exportDeckText = generateExportDeckText(
    cards,
    "\n",
    needFilling,
    cardFormat
  );

  exportDeckText = formatExportText(exportDeckText, exportFormat);

  navigator.clipboard
    .writeText(exportDeckText)
    .then(() => label && toast.success(label))
    .catch(() => toast.error("Failed to copy to clipboard"));
};

// Exporter vers Exalts Table
export const handleExportToExaltsTable: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText(
    cards,
    "%0A",
    needFilling,
    EXPORT_CARD_FORMAT.ID
  );
  const url = `https://exalts-table.com/deck-test/link/?decklist=${exportDeckText}`;
  window.open(url, "_blank");
};

// Exporter vers Altered DB
export const handleExportToAlteredDB: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText(
    cards,
    "%0A",
    needFilling,
    EXPORT_CARD_FORMAT.ID
  );
  const url = `https://altered-db.com/?d=${exportDeckText}`;
  window.open(url, "_blank");
};

// Exporter vers l'outil d'échange
export const handleExportToExchangeTool: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText(
    cards,
    "%0A",
    needFilling,
    EXPORT_CARD_FORMAT.ID
  );
  const url = `https://www.altered-card.com/exchange?list=${exportDeckText}`;
  window.open(url, "_blank");
};

// Exporter vers le simulateur de première main
export const handleSimulatorFirstHand: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText(
    cards,
    "%0A",
    needFilling,
    EXPORT_CARD_FORMAT.ID
  );
  const url = `https://altered-db.com/fhand/?cardslist=${exportDeckText}`;
  window.open(url, "_blank");
};

// Exporter vers Altered TGC Builder
export const handleAlteredTgcBuilder: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText(
    cards,
    "\n",
    needFilling,
    EXPORT_CARD_FORMAT.ID
  );
  const encodedDeck = encodeURIComponent(exportDeckText);
  const url = `https://altered.ajordat.com/fr/decks/new/?decklist=${encodedDeck}`;

  window.open(url, "_blank");
};
