import React, { useState } from "react";
import LegalModal from "./LegalModal";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const [isLegalModalOpen, setLegalModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <footer className="bg-darkBlue text-white text-xs lg:text-sm font-chillaxRegular py-2 w-full z-50">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <p>v2.0.0 {t("footer.copyright")}</p>
        <button
          onClick={() => setLegalModalOpen(true)}
          className="text-white hover:text-gray-300 focus:outline-none cursor-pointer whitespace-nowrap"
        >
          {t("footer.legalInfo")}
        </button>
      </div>
      <LegalModal
        isOpen={isLegalModalOpen}
        onClose={() => setLegalModalOpen(false)}
      />
    </footer>
  );
};

export default Footer;
