import React from "react";

interface LoadingProps {
  label: string;
}

const Loading: React.FC<LoadingProps> = ({ label }) => {
  return (
    <div className="flex w-full h-screen justify-center items-center">
      <p className="text-3xl font-chillaxBold uppercase text-center">{label}</p>
    </div>
  );
};

export default Loading;
