import React from "react";
import { useTranslation } from "react-i18next";

interface FilterOption {
  value: string;
  label: string;
}

interface FilterProps {
  id: string;
  label: string;
  value: string[];
  onChange: (value: string[]) => void;
  options: FilterOption[];
  multiSelect?: boolean;
}

const Filter: React.FC<FilterProps> = ({
  id,
  label,
  value,
  onChange,
  options,
  multiSelect = false,
}) => {
  const { t } = useTranslation();
  const handleCheckboxChange = (selectedValue: string) => {
    const currentSelection = Array.isArray(value) ? value : [value];
    const newSelection = currentSelection.includes(selectedValue)
      ? currentSelection.filter((val) => val !== selectedValue)
      : [...currentSelection, selectedValue];
    onChange(newSelection);
  };

  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-chillaxBold text-darkBlue mb-2"
      >
        {label}
      </label>
      {multiSelect ? (
        <div className="flex flex-col">
          {options.map((option) => (
            <label
              key={option.value}
              className="inline-flex items-center space-x-2"
            >
              <input
                type="checkbox"
                value={option.value}
                checked={Array.isArray(value) && value.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
                className="form-checkbox outline-none accent-darkBlue"
              />
              <span>{t(option.label)}</span>
            </label>
          ))}
        </div>
      ) : (
        <div className="relative">
          <select
            id={id}
            value={value}
            onChange={(event) => onChange([event.target.value])}
            className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M7 10l5 5 5-5H7z" />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
