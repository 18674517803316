import React from "react";

interface SeparatorProps {
  desktop?: boolean;
}

const Separator: React.FC<SeparatorProps> = ({ desktop }) => {
  return (
    <div
      className={`border-b-4 border-darkBlue rounded ${
        desktop ? "hidden lg:block" : ""
      }`}
    />
  );
};

export default Separator;
