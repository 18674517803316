import axios from "axios";
import { formatCards, formatUniqueCards } from "../utils/formatCards";
import { CardType, CardStatsInfo } from "../types/CardType";

interface FetchCollectionCardsResult {
  transformedCards: CardStatsInfo[];
  uniqueCards: CardType[];
}

export const fetchCollectionCards = async (
  token: string,
  setErrorMessage: (error: any) => void,
  onProgressUpdate: (message: string) => void
): Promise<FetchCollectionCardsResult | undefined> => {
  try {
    const itemsPerPage = 36;
    let currentPage = 1;
    let totalPages = 1;
    let loadedCardStats = 0;

    const allCardStats: CardStatsInfo[] = [];

    // Step 1: Fetch all card stats
    do {
      const statsResponse = await axios.get(
        "https://api.altered.gg/cards/stats",
        {
          params: {
            collection: true,
            page: currentPage,
            itemsPerPage,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": "fr-fr",
          },
        }
      );

      const statsData = statsResponse.data["hydra:member"];
      const filteredStats: CardStatsInfo[] = formatCards(statsData);

      allCardStats.push(...filteredStats);
      loadedCardStats += statsData.length;

      onProgressUpdate(
        ` ${loadedCardStats}/${statsResponse.data["hydra:totalItems"]}`
      );

      const totalItems = statsResponse.data["hydra:totalItems"];
      totalPages = Math.ceil(totalItems / itemsPerPage);
      currentPage += 1;
    } while (currentPage <= totalPages);

    // Step 2: Separate stats into unique and normal cards
    const uniqueCardStats = allCardStats.filter((card) =>
      card.reference?.includes("_U_")
    );
    const normalCardStats = allCardStats.filter(
      (card) => !card.reference?.includes("_U_")
    );

    // Step 3: Fetch all unique cards using their references
    const allUniqueCards: CardType[] = [];
    currentPage = 1;
    totalPages = 1;
    let loadedUniqueCards = 0;

    do {
      const cardsResponse = await axios.get("https://api.altered.gg/cards", {
        params: {
          itemsPerPage,
          collection: true,
          page: currentPage,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": "fr-fr",
        },
      });

      const cardData = cardsResponse.data["hydra:member"];
      const uniqueCardsOnPage = cardData.filter((card: any) =>
        uniqueCardStats?.some((stat) => stat.reference === card.reference)
      );
      const filteredCards: CardType[] = formatUniqueCards(
        uniqueCardsOnPage,
        uniqueCardStats
      );

      allUniqueCards.push(...filteredCards);
      loadedUniqueCards += uniqueCardsOnPage.length;

      onProgressUpdate(
        ` ${loadedUniqueCards}/${uniqueCardStats.length} (Uniques)`
      );

      const totalItems = cardsResponse.data["hydra:totalItems"];
      totalPages = Math.ceil(totalItems / itemsPerPage);
      currentPage += 1;
    } while (currentPage <= totalPages);

    return {
      transformedCards: normalCardStats,
      uniqueCards: allUniqueCards,
    };
  } catch (error) {
    setErrorMessage(error);
    console.error("Error fetching collection cards:", error);
    return undefined;
  }
};
