import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { useLogin } from "../hooks/useLogin";
import Button from "../components/Button";

interface LoginModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  //const [pseudo, setPseudo] = useState("");
  const [password, setPassword] = useState("");
  const { login, error } = useLogin();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await login(email, password, closeModal);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 z-[1000] bg-black"
    >
      <form
        onSubmit={handleSubmit}
        className="bg-beige p-8 space-y-8 rounded-lg rounded-t-none shadow-lg w-full max-w-md mx-auto border-4 border-t-0 border-darkBlue"
      >
        <div className="space-y-4">
          <div>
            <label className="block mb-2 text-sm font-chillaxMedium text-darkBlue">
              {`${t("user.email")}*`}
            </label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="w-full px-4 py-2 rounded-md border-4 border-darkBlue focus:outline-none"
            />
          </div>

          <div>
            <label className="block mb-2 text-sm font-chillaxMedium text-darkBlue">
              {`${t("user.password")}*`}
            </label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="w-full px-4 py-2 rounded-md border-4 border-darkBlue focus:outline-none"
            />
            <span className="font-chillaxRegular text-red-600">{error}</span>
          </div>
        </div>
        <Button isSubmit full>
          {t("actions.login")}
        </Button>
      </form>
    </Modal>
  );
};

export default LoginModal;
