import axios, { AxiosError } from "axios";
import {
  formatCardsFriend,
  formatUniqueCardsFriend,
} from "../utils/formatCards";
import { CardStatsInfo, CardType } from "../types/CardType";

interface FetchFriendsCollectionCardsResult {
  uniqueCards: CardType[];
  transformedCards: CardStatsInfo[];
}

export const fetchFriendTradingCard = async (
  token: string,
  userId: string,
  setErrorMessage: (error: any) => void
): Promise<FetchFriendsCollectionCardsResult | undefined> => {
  try {
    const response = await axios.get(
      "https://api.altered.gg/ownership_lists/tradelist/users/" + userId,
      {
        params: {
          itemsPerPage: 500,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": "fr-fr",
        },
      }
    );
    const transformedCards: CardStatsInfo[] = formatCardsFriend(
      response.data["hydra:member"]
    );
    const uniqueCards: CardType[] = formatUniqueCardsFriend(
      response.data["hydra:member"],
      userId
    );

    return { transformedCards, uniqueCards };
  } catch (error) {
    console.error("Error fetching cards:", error);
    setErrorMessage((error as AxiosError).response?.data);
    return undefined;
  }
};
