import { CARD_TYPE } from "../constants/constants";
import { CardType, CardStatsInfo } from "../types/CardType";

export const formatCards = (cards: any[]): CardStatsInfo[] => {
  return cards.map((card: any) => ({
    reference: card.reference,
    foiled: card.foiled,
    inMyCollection: card.inMyCollection,
    inMyTradelist: card.inMyTradelist,
    inMyWantlist: card.inMyWantlist,
  }));
};

export const formatCardsFriend = (cards: any[]): CardStatsInfo[] => {
  return cards
    .filter((card) => !card.reference.includes("_U_"))
    .map((card: any) => ({
      quantity: card.quantity,
      inMyCollection: card.inMyCollection,
      reference: card.reference,
    }));
};

export const formatUniqueCardsFriend = (
  cards: any[],
  userId: string
): any[] => {
  return cards
    .filter((card) => card.reference.includes("_U_"))
    .map((card) => ({
      reference: card.reference,
      rarity: CARD_TYPE.UNIQUE,
      owner: userId,
      lastUpdate: new Date().toISOString(),
      name: {
        en: card.name,
        fr: card.name,
        es: card.name,
        it: card.name,
        de: card.name,
      },
      imagePath: {
        en: card.imagePath,
        fr: card.imagePath,
        es: card.imagePath,
        it: card.imagePath,
        de: card.imagePath,
      },
      inMyCollection: card.inMyCollection,
    }));
};

export const formatUniqueCards = (
  uniqueCards: any[],
  uniqueCardStats: CardStatsInfo[]
): CardType[] => {
  return uniqueCards.map((card) => {
    // Find the matching stats for the current card
    const matchingStats = uniqueCardStats.find(
      (stat) => stat.reference === card.reference
    );

    // If no matching stats are found, provide default values
    const inMyCollection = matchingStats?.inMyCollection ?? 0;
    const inMyTradelist = matchingStats?.inMyTradelist ?? 0;
    const inMyWantlist = matchingStats?.inMyWantlist ?? false;
    const foiled = matchingStats?.foiled ?? false;

    return {
      reference: card.reference,
      //id: card.id,
      type: card.cardType.reference,
      subtypes: card.cardSubTypes
        ? card.cardSubTypes.map((subtype: any) => subtype.reference)
        : [],
      mainFaction: card.mainFaction.reference,
      rarity: card.rarity.reference,
      name: {
        en: card.name,
        fr: card.name,
        es: card.name,
        it: card.name,
        de: card.name,
      },
      imagePath: {
        en: card.imagePath,
        fr: card.imagePath,
        es: card.imagePath,
        it: card.imagePath,
        de: card.imagePath,
      },
      collectorNumberFormatted: {
        en: card.collectorNumberFormatted,
        fr: card.collectorNumberFormatted,
        es: card.collectorNumberFormatted,
        it: card.collectorNumberFormatted,
        de: card.collectorNumberFormatted,
      },
      qrUrlDetail: card.qrUrlDetail,
      elements: card.elements,
      inMyCollection: inMyCollection,
      inMyTradelist: inMyTradelist,
      inMyWantlist: inMyWantlist,
      foiled: foiled,
      latestAddition: card.latestAddition,
    };
  });
};

export const createMyCardsFromText = (
  allCards: CardType[],
  cardInfos: CardStatsInfo[]
): { includedCards: CardType[]; excludedCards: CardType[] } => {
  const includedCards: CardType[] = [];
  const excludedCards: CardType[] = [];

  allCards.forEach((card) => {
    const matchedInfo = cardInfos.find(
      (info) => info.reference === card.reference
    );
    if (matchedInfo) {
      includedCards.push({
        ...card,
        inMyCollection: matchedInfo.inMyCollection,
        inMyTradelist: matchedInfo.inMyTradelist,
        inMyWantlist: matchedInfo.inMyWantlist,
        latestAddition: matchedInfo.latestAddition,
      });
    } else {
      excludedCards.push(card);
    }
  });

  return {
    includedCards,
    excludedCards,
  };
};
